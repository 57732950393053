// src/components/Dropdown.js

import React from 'react';
import { css } from '@emotion/react';

/**
 * 汎用ドロップダウンメニューコンポーネント。
 * 選択肢（options）、選択された値（selectedValue）、値が変更された時のハンドラー（onChange）を受け取ります。
 */
const Dropdown = ({ styles, options, selectedValue, onChange, disabled }) => {
  return (
    <label css={[dropdownStyles.selectBox, styles]}>
      <select value={selectedValue} onChange={onChange} css={dropdownStyles.select} disabled={disabled}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

const dropdownStyles = {
  selectBox: css`
    position: relative;
    width: 550px;

    @media (max-width: 768px) {
      width: 100%;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      pointer-events: none;
    }
    &::before {
      display: inline-block;
      right: 0;
      width: 2.8em;
      height: 2.8em;
      border-radius: 0 3px 3px 0;
      background-color: #003366;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 1.4em;
      transform: translate(50%, -50%) rotate(45deg);
      width: 6px;
      height: 6px;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
    }
  `,
  select: css`
    appearance: none;
    width: 100%;
    height: 2.8em;
    padding: 0.4em 3.6em 0.4em 0.8em;
    border: none;
    border-radius: 3px;
    background-color: #e6edf3;
    color: #333;
    font-size: 1em;
    cursor: pointer;
    &:focus {
      outline: 2px solid #003366;
    }
  `,
};

export default Dropdown;
