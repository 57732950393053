import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../components/shared/button/Button';
import Input from '../components/shared/Input';
import Form from '../components/shared/Form';
import Logo from '../components/internal/Logo';
import Typography from '../components/shared/Typography';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import { sendRegistrationEmail } from '../actions/userRegistrationEmail';
import UserRegistrationGuide from '../components/auth/UserRegistrationGuide';
import Accordion from '../components/shared/Accordion';
import TermsOfService from '../components/auth/TermsOfService';

function UserRegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [subscribeToService, setSubscribeToService] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!username || !email || !confirmEmail || !phoneNumber) {
      alert('すべての入力項目を完了してください。');
      return;
    }
    if (!agreeToTerms || !subscribeToService) {
      alert('利用規約に同意し、サービス申し込みにチェックを入れてください。');
      return;
    }
    if (email !== confirmEmail) {
      alert('メールアドレスが一致しません。もう一度確認してください。');
      return;
    }
    const userData = { username, email, phone_number: phoneNumber };
    dispatch(sendRegistrationEmail(userData))
      .then(() => {
        alert('申し込みが完了しました。管理者がアカウントを作成後、メールでお知らせします。');
        navigate('/');
      })
      .catch((error) => {
        alert('申し込みに失敗しました: ' + error.message);
      });
  };

  return (
    <div css={styles.container}>
      <Logo alt="Logo" css={styles.logo} />
      <Typography variant="title" style={styles.title}>
        新規ユーザー登録申し込み
      </Typography>
      <UserRegistrationGuide style={styles.guide} />
      <Form style={styles.form} onSubmit={handleSubmit}>
        <Input
          style={styles.input}
          type="email"
          placeholder="メールアドレス"
          size="large"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          style={styles.input}
          type="email"
          placeholder="メールアドレスの確認"
          size="large"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
        />
        <Input
          style={styles.input}
          type="text"
          placeholder="氏名"
          size="large"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          style={styles.input}
          type="tel"
          placeholder="電話番号"
          size="large"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Accordion title="利用規約を読む">
          <TermsOfService />
        </Accordion>
        <div>
          <input type="checkbox" checked={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked)} />
          <label>上記の利用規約に同意する</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={subscribeToService}
            onChange={(e) => setSubscribeToService(e.target.checked)}
          />
          <label>月額5,500円のサービス利用を申し込む</label>
        </div>
        <Button style={styles.button} type="submit" variant="primary" size="large">
          申し込み
        </Button>
      </Form>
      <Footer />
    </div>
  );
}

export default UserRegisterPage;

// Emotionでのスタイル定義
const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
  `,
  title: css`
    margin-bottom: 40px;
    margin-top: 20px;
  `,
  form: css`
    margin: 50px 0;
    width: 80%;
  `,
  logo: css`
    width: 140px; // ロゴのサイズを調整
    margin-bottom: 20px; // ロゴ下の余白
  `,
  guide: css`
    width: 80%;
  `,
  input: css`
    width: 90%;
    @media (max-width: 768px) {
      min-width: 200px;
    }
  `,
  button: css`
    @media (max-width: 768px) {
      min-width: 200px;
    }
  `,
};
