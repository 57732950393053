// src/components/MessageDisplayArea.js

import React from 'react';
import { css } from '@emotion/react';
import Message from '../shared/Message';

const MessageDisplayArea = ({ messages }) => {
  //  if (messages.length === 0) {
  //    return null;
  //  }

  //    height: calc(100% - 120px);

  return (
    <div css={messages.length === 0 ? styles.displayAreaInitial : styles.displayAreaChatStarted}>
      {messages.map((message, index) => {
        const displayMessage = message.content.startsWith('【質問テンプレートをお送りします】')
          ? '【質問テンプレートをお送りします】'
          : message.content;
        return <Message key={index} text={displayMessage} type={message.role === 'user' ? 'user' : 'ai'} />;
      })}
    </div>
  );
};

const styles = {
  displayAreaInitial: css`
    padding: 20px;
    overflow-y: auto;
    max-height: 300px; // 適切な高さに調整
    height: calc(100% - 180px);
  `,
  displayAreaChatStarted: css`
    padding: 20px;
    overflow-y: auto;
    max-height: 300px; // 適切な高さに調整
    height: calc(100% - 120px);
  `,
};

export default MessageDisplayArea;
