// src/pages/QAMajorCategoryPage.js

import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import axios from 'axios';
import Typography from '../components/shared/Typography';
import QACategoryList from '../components/qa/QACategoryList';
import QAList from '../components/qa/QAList';
import FloatingActionButton from '../components/shared/button/FloatingActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchModal from '../components/shared/modal/SearchModal';

function QAMajorCategoryPage() {
  const navigate = useNavigate();
  const { majorCategoryId } = useParams(); // URLからcategoryIdを取得
  const [majorCategoryInfo, setMajorCategoryInfo] = useState({}); // カテゴリー情報を保存するためのステート
  const [selectedMediumCategory, setSelectedMediumCategory] = useState(null);
  const [selectedMinorCategory, setSelectedMinorCategory] = useState(null);
  const [qaListCategory, setQAListCategory] = useState(null);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  // 各セクションのrefを作成
  const minorCategoryRef = useRef(null);
  const qaListRef = useRef(null);

  const isMobile = () => window.innerWidth <= 768;

  // スクロールを実行する関数
  const scrollToRef = (ref) => {
    // ユーザーがインタラクションした後でのみスクロールを実行
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // ページの初期状態でスクロール位置をリセットする
    window.scrollTo(0, 0);

    // 選択されたメインカテゴリーの情報を取得
    const fetchMajorCategoryInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/qa/category/categories/${majorCategoryId}`);
        setMajorCategoryInfo(response.data); // 取得したカテゴリー情報をステートに保存
      } catch (error) {
        console.error('カテゴリ情報の取得に失敗しました', error);
      }
    };

    fetchMajorCategoryInfo();

    // 大カテゴリーに属するQAを初期表示
    setQAListCategory(majorCategoryId);
  }, [majorCategoryId]); // categoryIdが変わったらこのeffectを再実行

  const handleMediumCategorySelect = (mediumCategoryId) => {
    setSelectedMediumCategory(mediumCategoryId);
    setQAListCategory(mediumCategoryId); // 中カテゴリーに属するQAを取得
  };

  const handleMinorCategorySelect = (minorCategoryId) => {
    setSelectedMinorCategory(minorCategoryId);
    setQAListCategory(minorCategoryId); // 小カテゴリーに属するQAを取得
  };

  const onMinorCategoryListLoadingComplete = () => {
    // ローディング完了後に行う処理
    if (isMobile() && selectedMediumCategory) {
      scrollToRef(minorCategoryRef); // QAリストの先頭へスクロール
    }
  };

  const onQAListLoadingComplete = () => {
    // ローディング完了後に行う処理
    if (selectedMinorCategory) {
      scrollToRef(qaListRef); // QAリストの先頭へスクロール
    }
  };

  return (
    <div>
      <NavigationBar />
      {/* ブレッドクラムナビゲーション */}
      {majorCategoryInfo.name && (
        <Typography variant="title" style={styles.pageTitle}>
          {majorCategoryInfo.name}
        </Typography>
      )}
      <Typography variant="subtitle" style={styles.categorySectionTitle}>
        カテゴリーを選んでください
      </Typography>
      {/* カテゴリー選択 */}
      <div css={styles.categorySectionContainer}>
        <QACategoryList
          parentCategoryId={majorCategoryId}
          listTitle="中カテゴリーを選んでください"
          onCategorySelect={handleMediumCategorySelect}
          selectedCategory={selectedMediumCategory}
        />
        <QACategoryList
          parentCategoryId={selectedMediumCategory}
          listTitle="小カテゴリーを選んでください"
          onCategorySelect={handleMinorCategorySelect}
          onLoadingComplete={onMinorCategoryListLoadingComplete}
          selectedCategory={selectedMinorCategory}
          isDisabled={!selectedMediumCategory}
          ref={minorCategoryRef}
        />
      </div>
      {/* QA表示 */}
      <div css={styles.qaListSectionContainer}>
        <QAList
          categoryId={qaListCategory}
          listTitle="よくあるQA"
          ref={qaListRef}
          onLoadingComplete={onQAListLoadingComplete}
        />
      </div>
      {/* 検索モーダル */}
      <FloatingActionButton tooltip="検索はこちら" onClick={() => setIsOpenSearchModal(true)}>
        <FontAwesomeIcon icon={faSearch} />
      </FloatingActionButton>
      <SearchModal
        isOpen={isOpenSearchModal}
        onClose={() => setIsOpenSearchModal(false)}
        onSearch={(keyword) => {
          setIsOpenSearchModal(false);
          // 検索結果ページへの遷移ロジック
          navigate(`/qa/search?query=${encodeURIComponent(keyword)}`);
        }}
        placeholder="キーワードを入力して質問を検索します"
      />
      <Footer />
    </div>
  );
}

const styles = {
  pageTitle: css`
    padding-top: 40px;
    margin: 0 auto; /* Center the grid */
    text-align: center;
    @media (max-width: 768px) {
      padding-top: 70px;
    }
  `,
  searchBarContainer: css`
    margin-top: 20px;
    padding: 20px;
  `,
  categorySectionTitle: css`
    padding-top: 20px;
    margin: 0 auto; /* Center the grid */
    text-align: center;
  `,
  categorySectionContainer: css`
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    gap: 30px;

    // モバイルビューでのスタイル
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 20px;
    }
  `,
  qaListSectionContainer: css`
    padding: 20px 50px;
    margin-bottom: 500px;

    // モバイルビューでのスタイル
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
};

export default QAMajorCategoryPage;
