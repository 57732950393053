// src/components/AIChatPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import axios from 'axios';
import { useSelector } from 'react-redux'; // ログイン状態をチェックするために必要
import Typography from '../components/shared/Typography';
import NavigationBar from '../components/internal/NavigationBar';
import MessageDisplayArea from '../components/internal/MessageDisplayArea';
import FloatingActionButton from '../components/shared/button/FloatingActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faDownload } from '@fortawesome/free-solid-svg-icons';
import { BeatLoader } from 'react-spinners';
import AIChatUserInputController from '../components/aichat/AIChatUserInputController';

/**
 * AIチャットページのメインコンポーネント。
 * ナビゲーションバー、税務相談フィルタ、チャットセクションを含みます。
 */
const AIChatPage = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const userSession = useSelector((state) => state.userSession); // Reduxからユーザーのログイン状態を取得
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ユーザーが未ログインの場合、トップページにリダイレクトする
  useEffect(() => {
    if (!userSession.isUserAuthenticated) {
      navigate('/');
    }
  }, [userSession.isUserAuthenticated, navigate]);

  // ファイルダウンロード関数
  const handleDownloadExcel = () => {
    const downloadUrl = `${process.env.REACT_APP_API_URL}/chat/ai_chat_template/download_excel`;
    window.open(downloadUrl, '_blank');
  };

  const handleSendMessage = async (userMessage) => {
    // ユーザーがチャットを開始したとする状態変化
    if (!isChatStarted && userMessage.trim()) {
      setIsChatStarted(true);
    }
    // 既存のメッセージにユーザーのメッセージを追加
    setMessages((messages) => [...messages, { role: 'user', content: userMessage }]);
    setIsLoading(true);

    try {
      // サーバーにメッセージを送信
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/ai_chat/guest_chat`, {
        message: userMessage,
        conversation_history: messages.map((msg) => ({
          role: msg.role,
          content: msg.content,
        })),
      });

      // AI のレスポンスをメッセージリストに追加
      setMessages((messages) => [...messages, { role: 'assistant', content: response.data.ai_response }]);
    } catch (error) {
      console.error('メッセージ送信に失敗しました', error);
      // エラーメッセージをメッセージリストに追加
      setMessages((messages) => [...messages, { role: 'assistant', content: 'メッセージの送信に失敗しました。' }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <div css={styles.introSection}>
          <Typography variant="title" style={styles.headerTitle}>
            自分で相談する
          </Typography>
        </div>

        <div css={styles.chatArea}>
          <MessageDisplayArea messages={messages} />
          {isLoading ? (
            // ローディング中にBeatLoaderを表示
            <div css={styles.loader}>
              <BeatLoader color="#004c98" />
            </div>
          ) : (
            <AIChatUserInputController onSendMessage={handleSendMessage} fileDropAreaVisible={!isChatStarted} />
          )}
        </div>
      </div>
      <FloatingActionButton
        tooltip="さらに詳しい使い方はこちら"
        style={styles.fab}
        onClick={() => navigate(`/how-to-ai-chat`)}
      >
        <FontAwesomeIcon icon={faQuestion} />
      </FloatingActionButton>
      <FloatingActionButton tooltip="質問テンプレートをダウンロード" onClick={handleDownloadExcel}>
        <FontAwesomeIcon icon={faDownload} />
      </FloatingActionButton>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 60px; // ナビゲーションバーの高さに応じて調整
    @media (max-width: 768px) {
      padding: 20px 10px;
    }
  `,
  introSection: css`
    text-align: center;
    padding: 0px 30px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    margin-bottom: 10px;
    width: 80%;
    max-width: 1085px;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 0;
      width: 95%;
    }
  `,
  logoStyle: css`
    width: 140px;
    margin-bottom: 30px;
  `,
  headerTitle: css`
    margin: 0;
    margin-bottom: 10px;
  `,
  headerDescription: css`
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
  `,
  qaLink: css`
    font-size: 1.1rem;
    color: #3b31d7;
    margin: 0 auto;
    min-width: auto;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: cornflowerblue;
    transition: opacity 0.3s; // 透明度の変化にトランジションを追加
    cursor: pointer;
    &:hover {
      opacity: 0.5; // ホバー時に透明度を変更
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  `,
  chatArea: css`
    position: absolute;
    height: calc(100% - 160px);
    width: 80%;
    max-width: 1085px;
    margin-top: 20px;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 95%;
      margin-top: 60px;
    }
  `,
  loader: css`
    display: flex;
    justify-content: center;
    padding: 20px; // チャットエリア内でのローダーの位置を調整
  `,
  fab: css`
    bottom: auto;
    top: 60px;
  `,
  // その他のスタイルをここに追加
};

export default AIChatPage;
