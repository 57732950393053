import React, { useState, useRef } from 'react';
import UserInputBox from '../shared/UserInputBox';
import FileDropArea from '../shared/FileDropArea';
import * as XLSX from 'xlsx';

const AIChatUserInputController = ({ onSendMessage, fileDropAreaVisible }) => {
  const [templateInputMessage, setTemplateInputMessage] = useState(''); // 質問テンプレートの値を保持する状態
  const inputBoxRef = useRef(null); // UserInputBoxへの参照

  // メッセージとファイル選択のロジックを管理
  // 省略: メッセージ送信やファイル選択のハンドラ
  const handleFileSelected = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets['入力シート']; // 「入力シート」を指定
      // header:1 オプションで1行目をヘッダとして扱うため、実際のデータ行は2行目から開始されます
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });

      let messages = [];
      messages.push('【質問テンプレートをお送りします】');

      // slice(1) で1行目（配列の0番目）をスキップ
      for (let row of json.slice(1)) {
        // A～D列がすべて空白の行を見つけたらループを抜ける
        if (!row[0] && !row[1] && !row[2] && !row[3]) break;

        // D列の値をmessages配列に追加
        if (row[3] && row[2]) messages.push(row[3]);
      }

      // D列の値をtemplateMessages配列に保持
      setTemplateInputMessage(messages.join('\n')); // messages配列の先頭（説明行）を除外
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <UserInputBox
        templateInputMessage={templateInputMessage}
        setTemplateInputMessage={setTemplateInputMessage}
        onSendMessage={onSendMessage}
        onFileSelected={handleFileSelected}
        ref={inputBoxRef}
      />
      {fileDropAreaVisible && <FileDropArea onFileSelected={(file) => handleFileSelected(file)} />}
    </>
  );
};

export default AIChatUserInputController;
