// src/reducers/userPayment.js

import {
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  SET_DEFAULT_PAYMENT_SUCCESS,
  ADD_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  SET_DEFAULT_PAYMENT_FAILURE,
  DELETE_PAYMENT_METHOD_FAILURE,
} from '../actions/types';

const initialState = {
  paymentMethods: [],
  selectedPaymentMethod: null,
  loading: false,
  error: null,
};

const userPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, paymentMethods: action.payload, loading: false };
    case FETCH_PAYMENT_METHODS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case ADD_PAYMENT_METHOD_SUCCESS:
      // 決済方法の追加や更新の処理
      break;
    case SET_DEFAULT_PAYMENT_SUCCESS:
      return { ...state, selectedPaymentMethod: action.payload };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      // 決済方法の削除処理
      break;
    case ADD_PAYMENT_METHOD_FAILURE:
    case SET_DEFAULT_PAYMENT_FAILURE:
    case DELETE_PAYMENT_METHOD_FAILURE:
      // エラーハンドリング
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default userPaymentReducer;
