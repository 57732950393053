// src/components/UserRegistrationGuide.js

import React from 'react';
import { css } from '@emotion/react';
import Typography from '../shared/Typography';
import UserRegistrationStepCard from './UserRegistrationStepCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faEnvelopeOpenText,
  faCreditCard,
  faUserCheck,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';

const UserRegistrationGuide = ({ style }) => {
  // Define steps for the registration manual
  const steps = [
    {
      index: 1,
      title: '申込フォームの送信',
      description: '必要な情報を入力して申込フォームを送信します。',
      icon: <FontAwesomeIcon icon={faPaperPlane} size="3x" />,
    },
    {
      index: 2,
      title: '申込受付メールを確認',
      description: '申し込み後、受付確認メールが届きます。',
      icon: <FontAwesomeIcon icon={faEnvelopeOpenText} size="3x" />,
    },
    {
      index: 3,
      title: '月額利用料の支払',
      description: '登録された支払い方法で初月の利用料を支払います。',
      icon: <FontAwesomeIcon icon={faCreditCard} size="3x" />,
    },
    {
      index: 4,
      title: 'AI税務相談アカウントの確認',
      description: '支払い確認後、アカウントが有効化されます。',
      icon: <FontAwesomeIcon icon={faUserCheck} size="3x" />,
    },
    {
      index: 5,
      title: 'AI税務相談利用開始',
      description: 'アカウント設定完了後、AI税務相談を利用開始します。',
      icon: <FontAwesomeIcon icon={faPlayCircle} size="3x" />,
    },
  ];

  return (
    <div css={[styles.registrationGuideSection, style]}>
      <div css={styles.registrationStepSection}>
        {steps.map((step) => (
          <UserRegistrationStepCard key={step.index} step={step} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  registrationGuideSection: css`
    border-radius: 10px;
    background: rgba(100, 100, 200, 0.3); // Adjust color for distinction
  `,
  registrationGuideTitle: css`
    padding-top: 20px;
    margin: 0 auto;
  `,
  registrationStepSection: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 40px 20px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;

    @media (max-width: 500px) {
      padding: 20px;
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
  `,
};

export default UserRegistrationGuide;
