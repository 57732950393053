import React from 'react';
import { css } from '@emotion/react';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import Typography from '../components/shared/Typography';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <Typography variant="title" style={styles.title}>
          AI税務相談サービスプライバシーポリシー
        </Typography>
        <h2 css={styles.subtitle}>1. 総則</h2>
        <p>
          当サービス（以下、「本サービス」）は、利用者のプライバシーを重視し、個人情報の保護に最大限の注意を払い、その安全な管理を徹底します。本プライバシーポリシーは、本サービスが利用者から収集する情報の種類、利用目的、保護措置について定めます。
        </p>
        <h2 css={styles.subtitle}>2. 収集する情報と利用目的</h2>
        <ul>
          <li>
            個人情報：利用者の識別に必要な情報（氏名、住所、連絡先等）を、サービス提供、問い合わせ対応、情報提供のために利用します。
          </li>
          <li>
            利用情報：サービス利用時のアクセスログ、利用履歴等をサービス改善、ユーザーエクスペリエンス向上のために分析し利用します。
          </li>
        </ul>
        <h2 css={styles.subtitle}>3. 情報共有と開示</h2>
        <p>本サービスは、以下の場合を除き、利用者の同意なく第三者へ個人情報を共有、開示しません：</p>
        <ul>
          <li>法令に基づく開示が必要な場合</li>
          <li>利用者または公共の安全を守るために必要な場合</li>
          <li>サービス提供に関わる業務を委託する場合（情報管理に関する契約を締結している業者に限る）</li>
        </ul>
        <h2 css={styles.subtitle}>4. 情報漏洩対策</h2>
        <ul>
          <li>情報セキュリティポリシーの定期的な見直しと更新</li>
          <li>従業員へのプライバシー保護研修の実施</li>
          <li>アクセス制御、通信の暗号化など技術的対策の強化</li>
          <li>安全な情報管理システムの導入と監視</li>
        </ul>
        <h2 css={styles.subtitle}>5. 利用者の権利</h2>
        <ul>
          <li>アクセス権：自己の個人情報にアクセスし、確認する権利</li>
          <li>訂正権：不正確な情報の訂正を要求する権利</li>
          <li>削除権：特定の条件下での個人情報の削除を要求する権利</li>
        </ul>
        <h2 css={styles.subtitle}>6. プライバシーポリシーの変更</h2>
        <p>
          本サービスは、法令変更やサービス内容の変更に伴い、本プライバシーポリシーを更新することがあります。重要な変更があった場合は、ウェブサイトまたはメールで通知します。
        </p>
        <h2 css={styles.subtitle}>7. お問い合わせ</h2>
        <p>
          プライバシーポリシーに関するお問い合わせや個人情報の取り扱いに関するご相談は、以下までお願いします。
          <br />
          <br />
          メールアドレス
          <br />
          kengo.s@storys-labo.com
        </p>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  container: css`
    color: #002244;
    font-family: 'Noto Sans JP', 'system-ui', sans-serif;
    max-height: 600px;
    overflow-y: scroll;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 40px;
    margin-top: 80px;
    @media (max-width: 768px) {
      padding: 20px;
      margin: 20px;
      margin-top: 80px;
    }
  `,
  title: css`
    font-size: 24px;
    font-family: 'Noto Sans JP', 'system-ui', sans-serif;
    font-weight: bold; // フォントの太さを増やす
    color: #002244; // コントラストを高めるために色を少し濃くする
  `,
  subtitle: css`
    font-size: 18px;
    font-family: 'Noto Sans JP', 'system-ui', sans-serif;
    color: #002244; // コントラストを高めるために色を少し濃くする
  `,
};

export default PrivacyPolicyPage;
