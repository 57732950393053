// src/components/QACategorySquareCard.js

import React from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import categoriesConfig from '../../config/categoriesConfig';

function QACategorySquareCard({ category }) {
  const navigate = useNavigate();
  const config = categoriesConfig[category.name] || categoriesConfig['default'];

  const handleCardClick = () => {
    navigate(`/qa/category/${category.id}`);
  };

  return (
    <div css={styles.card} onClick={handleCardClick}>
      <div css={styles.iconContainer}>
        <FontAwesomeIcon icon={config.icon} size="3x" />
      </div>
      <div css={styles.content}>
        <div css={styles.title}>{category.name}</div>
        <div css={styles.description}>{config.description}</div>
      </div>
    </div>
  );
}

const styles = {
  card: css`
    height: 280px; // Fix card height
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: #fff;
    transition: box-shadow 0.3s, transform 0.3s;
    text-align: center;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px);
    }
  `,
  logo: css`
    width: 80px; // Adjust as needed
    height: auto;
    margin-bottom: 16px; // Adjust as needed
  `,
  iconContainer: css`
    color: #2c3e50;
    height: 100px; // Fix icon area height
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  icon: css`
    color: #2c3e50; // アイコンの色
    margin-bottom: 16px; // アイコンの下部のマージン
    // アイコンサイズはFontAwesomeIconのsize属性で制御するため、ここでは指定しない
  `,
  content: css`
    margin-top: 16px; // Adjust as needed
  `,
  title: css`
    font-size: 1rem;
    color: #032d60;
    background-color: rgba(30, 167, 253, 0.2);
    display: inline-block;
    padding: 5px 0;
    border-radius: 25px;
    margin-bottom: 8px;
    min-width: 90%;
  `,
  description: css`
    font-size: 0.8rem;
    color: #666;
  `,
  // Other styles...
};
export default QACategorySquareCard;
