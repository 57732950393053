// src/components/SendMessageButton.js

import React from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const SendMessageButton = ({ onClick }) => {
  return (
    <button css={styles.sendButton} onClick={onClick}>
      <FontAwesomeIcon icon={faPaperPlane} />
    </button>
  );
};

const styles = {
  sendButton: css`
    padding: 10px;
    background: #007bff;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    &:hover {
      background: #0056b3;
    }
  `,
};

export default SendMessageButton;
