// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userRegistrationReducer from './reducers/userRegistration';
import userSessionReducer from './reducers/userSession';
import userPaymentReducer from './reducers/userPayment';
import userProfileReducer from './reducers/userProfile';

const reducer = {
  userRegistration: userRegistrationReducer,
  userSession: userSessionReducer,
  userPayment: userPaymentReducer,
  userProfile: userProfileReducer,
};

const initialState = {};

const middleware = [thunk];

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
