/*
  src/App.js

  このファイルはReactアプリケーションのルートコンポーネントを定義します。
  ここでは、react-router-domを使って、アプリケーションのルーティングを設定しています。
  アプリケーションの各ページに対応するルートが設定されており、
  ブラウザのURLに応じて表示するページが切り替わります。
*/
import React, { useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from './store';
import { userLogout, refreshUserAccessToken, getUserProfile, validateUserToken } from './actions/userSession';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import QAHomePage from './pages/QAHomePage';
import QAMajorCategoryPage from './pages/QAMajorCategoryPage';
import QADetailPage from './pages/QADetailPage';
import QASearchPage from './pages/QASearchPage';
import AIChatPage from './pages/AIChatPage';
import HowToAIPage from './pages/HowToAIPage';
import UserRegisterPage from './pages/UserRegisterPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

function App() {
  // コンポーネントがマウントされたとき（アプリ起動, ページ読み込み）に一度だけ実行
  useEffect(() => {
    // アプリ起動時にトークンの有効性を確認
    const checkTokenValidity = async () => {
      const accessToken = localStorage.getItem('gptdexTaxUserAccessToken');
      const refreshToken = localStorage.getItem('gptdexTaxUserRefreshToken');

      if (!accessToken && !refreshToken) {
        // アクセストークンもリフレッシュトークンもない場合、ログアウト処理
        store.dispatch(userLogout());
      } else if (accessToken) {
        try {
          // アクセストークンの有効性を確認
          const tokenValid = await store.dispatch(validateUserToken(accessToken));
          if (tokenValid) {
            // アクセストークンが有効な場合、管理者情報を取得
            store.dispatch(getUserProfile(accessToken));
          } else {
            // トークンが無効の場合、ログアウト処理
            store.dispatch(userLogout());
          }
        } catch (error) {
          if (refreshToken) {
            // トークンが無効の場合、リフレッシュトークンを使用して更新
            store
              .dispatch(refreshUserAccessToken(refreshToken))
              .then((isValidToken) => {
                if (!isValidToken) {
                  // リフレッシュに失敗した場合、ログアウト処理
                  store.dispatch(userLogout());
                }
              })
              .catch(() => {
                // リフレッシュで例外発生した場合、ログアウト処理
                store.dispatch(userLogout());
              });
          } else {
            // リフレッシュトークンがない場合、ログアウト処理
            store.dispatch(userLogout());
          }
        }
      }
    };

    checkTokenValidity();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Global styles={globalStyle} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/user-register" element={<UserRegisterPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/qa/home" element={<QAHomePage />} />
            <Route path="/qa/category/:majorCategoryId" element={<QAMajorCategoryPage />} />
            <Route path="/qa/questions/:questionId" element={<QADetailPage />} />
            <Route path="/qa/search" element={<QASearchPage />} />
            <Route path="/ai-chat" element={<AIChatPage />} />
            <Route path="/how-to-ai-chat" element={<HowToAIPage />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

// Emotionでのスタイル定義
const globalStyle = css`
  html {
    font-size: 18px;
  }
  body {
    margin: 0;
    font-size: 1rem;
    font: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .visuallyhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;
