// src/components/QAListItem.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // FontAwesomeのアイコンをインポート

// Helper function to truncate text
const truncateText = (text, length) => {
  return text.length > length ? text.substring(0, length) + '…' : text;
};

// QAコンポーネント
const QAListItem = ({ questionId, question, answer }) => {
  const navigate = useNavigate();

  // QAの詳細ページへの遷移を処理
  const goToQADetail = () => {
    navigate(`/qa/questions/${questionId}`);
  };

  return (
    <div css={styles.questionItem} onClick={goToQADetail}>
      <FontAwesomeIcon icon={faQuestionCircle} css={styles.questionIcon} />
      <span css={styles.questionText}>{truncateText(question, 30)}</span>
      <FontAwesomeIcon icon={faChevronRight} css={styles.chevronIcon} />
    </div>
  );
};

// スタイル定義
const styles = {
  questionItem: css`
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid #16325c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f9f9f9;
    }
  `,
  questionIcon: css`
    color: #16325c;
    margin-right: 15px;
    font-size: 30px;
    margin-left: 5px;
  `,
  questionText: css`
    flex-grow: 1;
    text-align: left;
  `,
  chevronIcon: css`
    color: #cccccc;
  `,
  // その他のスタイル...
};

export default QAListItem;
