/**
 * src/actions/userSession.js
 *
 * このファイルは、ユーザーセッションに関連するReduxのアクションクリエーターを定義します。
 * 主に以下の機能を提供します：
 *   - ユーザーのログイン
 *   - ユーザーのログアウト
 *   - アクセストークンのリフレッシュ
 *   - ユーザープロファイル情報の取得
 *   - トークンの有効性の確認
 *
 * 各アクションは非同期のAPIリクエストを行い、その結果に基づいてReduxのステートを更新します。
 * APIエンドポイントのURLは環境変数から取得し、適切なHTTPリクエストを実行します。
 *
 * 使用上の注意:
 * - APIエンドポイントのURLは、バックエンドの設定に合わせて適宜調整してください。
 * - エラーハンドリングは、ユーザーフレンドリーなフィードバックを提供するために重要です。
 * - 各アクションクリエーターは非同期操作を含む場合、Redux Thunkを使用しています。
 */

import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from './types';

// ユーザーログインリクエストを送信するアクション
export const userLogin = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/user_session/login`, {
      username,
      password,
    });

    localStorage.setItem('gptdexTaxUserAccessToken', response.data.access_token);
    localStorage.setItem('gptdexTaxUserRefreshToken', response.data.refresh_token);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    return Promise.resolve(response.data); // 正常にログインした場合のPromiseを解決
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : 'ユーザーログイン中にエラーが発生しました。';
    dispatch({
      type: LOGIN_FAILURE,
      payload: errorMessage,
    });

    return Promise.reject(errorMessage); // エラーが発生した場合のPromiseを拒否
  }
};

// ユーザーログアウトリクエストを送信するアクション
export const userLogout = () => async (dispatch) => {
  localStorage.removeItem('gptdexTaxUserAccessToken');
  localStorage.removeItem('gptdexTaxUserRefreshToken');

  dispatch({
    type: LOGOUT,
  });
};

// アクセストークンリフレッシュのリクエストを送信するアクション
export const refreshUserAccessToken = (refreshToken) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/user_session/refresh_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    if (response.data && response.data.access_token) {
      localStorage.setItem('gptdexTaxUserAccessToken', response.data.access_token);
      dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: response.data.access_token });
    } else {
      dispatch(userLogout());
    }
  } catch (err) {
    console.error('Error refreshing user access token:', err);
    dispatch(userLogout());
  }
};

// ユーザープロファイル情報取得のアクション
export const getUserProfile = (accessToken) => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user_session/profile`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    dispatch({
      type: PROFILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_FAILURE,
      payload: error.response ? error.response.data.message : 'ユーザー情報の取得に失敗しました。',
    });
  }
};

// トークンの有効性確認のアクション
export const validateUserToken = (accessToken) => async (dispatch) => {
  try {
    await axios.get(`${process.env.REACT_APP_API_URL}/auth/user_session/validate_token`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    dispatch({
      type: VALIDATE_TOKEN_SUCCESS,
    });
    return true; // トークンが有効
  } catch (error) {
    dispatch({
      type: VALIDATE_TOKEN_FAILURE,
      payload: error.response ? error.response.data.message : 'トークンの有効性の確認に失敗しました。',
    });
    return false; // トークンが無効
  }
};
