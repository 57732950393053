import React, { useState } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ placeholder = '検索する値を入力します', onSearch, style }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue.trim() !== '') {
      onSearch(inputValue.trim());
    }
  };

  const handleInputChange = (e) => {
    // 日本語入力を確定した時のみinputValueを更新
    if (e.target.type === 'text') {
      setInputValue(e.target.value);
    }
  };

  return (
    <div css={[searchContainerStyle, style]}>
      <form css={searchFormStyle} onSubmit={handleSearch}>
        <input
          css={searchInputStyle}
          type="text"
          name="search"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          autoFocus
          autocomplete="off" // 自動補完を無効にする
        />
        <button css={searchButtonStyle} type="submit">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;

const searchContainerStyle = css`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const searchFormStyle = css`
  display: flex;
  justify-content: center;
`;

const searchInputStyle = css`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 20px;
  border: none;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  outline: none;
  &::placeholder {
    color: #aaa;
  }
  &:focus {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  }
`;

const searchButtonStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
