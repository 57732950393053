// src/actions/types.js

/**
 * types.js
 *
 * このモジュールは、アプリケーション内で使用されるReduxアクションタイプを定義します。
 * 各アクションタイプは、アプリケーションの異なる部分で発生する様々なイベントを表します。
 *
 * 使用上の注意:
 * - このファイルに追加されるアクションタイプは、明確で一意的な名前を持つべきです。
 * - 新しい機能を実装する際には、関連するアクションタイプをここに追加します。
 * - アクションタイプは、アクションクリエーターとリデューサーで使用されます。
 */

// 認証関連のアクションタイプ
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

// メール認証関連のアクションタイプ
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS';
export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE';
export const EMAIL_CONFIRM_SUCCESS = 'EMAIL_CONFIRM_SUCCESS';
export const EMAIL_CONFIRM_FAILURE = 'EMAIL_CONFIRM_FAILURE';

// パスワードリセット関連のアクションタイプ
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAILURE = 'PASSWORD_RESET_REQUEST_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

// 決済方法関連のアクションタイプ
export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';
export const SET_DEFAULT_PAYMENT_SUCCESS = 'SET_DEFAULT_PAYMENT_SUCCESS';
export const SET_DEFAULT_PAYMENT_FAILURE = 'SET_DEFAULT_PAYMENT_FAILURE';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

// ユーザープロファイル関連のアクションタイプ
export const USER_PROFILE_GET = 'GET_USER_PROFILE';
export const USER_PROFILE_GET_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const USER_PROFILE_GET_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';

// ユーザー登録申し込み関連のアクションタイプ
export const REGISTRATION_EMAIL_REQUEST = 'REGISTRATION_EMAIL_REQUEST';
export const REGISTRATION_EMAIL_SENT = 'REGISTRATION_EMAIL_SENT';
export const REGISTRATION_EMAIL_FAILED = 'REGISTRATION_EMAIL_FAILED';
