// src/components/shared/Modal/SearchModal.js
import React from 'react';
import { css } from '@emotion/react';
import Modal from '../modal/Modal'; // 汎用的なModalコンポーネントをインポート
import SearchBar from '../SearchBar'; // SearchBarコンポーネントをインポート

const SearchModal = ({ isOpen, onClose, onSearch, placeholder }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SearchBar onSearch={onSearch} placeholder={placeholder} style={searchBarStyle} />
    </Modal>
  );
};

export default SearchModal;

const searchBarStyle = css`
  width: 95%;
`;
