import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import GuideSection from '../components/howto/GuideSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Typography from '../components/shared/Typography';
import FloatingActionButton from '../components/shared/button/FloatingActionButton';

const HowToAIPage = () => {
  const navigate = useNavigate();
  const userSession = useSelector((state) => state.userSession);

  // ユーザーが未ログインの場合、トップページにリダイレクトする
  useEffect(() => {
    if (!userSession.isUserAuthenticated) {
      navigate('/');
    }
  }, [userSession.isUserAuthenticated, navigate]);

  // ファイルダウンロード関数
  const handleDownloadExcel = () => {
    const downloadUrl = `${process.env.REACT_APP_API_URL}/chat/ai_chat_template/download_excel`;
    window.open(downloadUrl, '_blank');
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <Typography variant="title" style={styles.pageTitle}>
          AIチャットの使い方ガイド
        </Typography>
        <Typography variant="text" style={styles.pageSubtitle}>
          AIとの対話でより良い結果を得るためのガイドラインを紹介します。効果的な質問をするためのポイントを押さえましょう。
        </Typography>

        {/* ガイドセクション */}
        <GuideSection
          step="1"
          title="質問のタイプを選択"
          description="まず、初回の質問か、フォローアップの質問かを選択してください。質問のタイプによって、AIの提供する情報や質問の流れが異なります。"
        />
        <GuideSection
          step="2"
          title="基本情報の入力"
          description="次に、自分の立場（法人、個人事業主、会社員など）や、質問のカテゴリー（例：法人設立、個人開業、消費税など）を選択します。これらの情報は、AIがより適切な回答を提供するために必要です。"
        />
        <GuideSection
          step="3"
          title="詳細情報の提供"
          description="選択したカテゴリーに応じて、より詳細な情報を提供してください。たとえば、法人設立に関する質問の場合、予定している事業内容、資金調達計画、従業員の状況など、具体的な状況を伝えることが重要です。これにより、AIは質問の背景を理解し、より具体的なアドバイスを提供できます。"
        />
        <GuideSection
          step="4"
          title="目的の選択"
          description="チャットの目的を明確にしてください。具体的な解決策を求める場合は「課題解決」を、税法の基礎知識を学びたい場合は「一般論」を選択します。目的に応じて、AIは適切な情報を提供します。"
        />
        <GuideSection
          step="5"
          title="質問内容の記入"
          description="最後に、具体的な質問を記入してください。質問に対する自分の見解や期待する回答がある場合は、それも併せて記載すると、より有益な回答を得られる可能性が高まります。"
        />
        {/* 他のステップも同様にGuideSectionコンポーネントを追加 */}
      </div>
      <FloatingActionButton tooltip="質問テンプレートをダウンロード" onClick={handleDownloadExcel}>
        <FontAwesomeIcon icon={faDownload} />
      </FloatingActionButton>
      <Footer />
    </div>
  );
};

export default HowToAIPage;

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  `,
  pageTitle: css`
    padding-top: 30px;
    padding-bottom: 30px;
  `,
  pageSubtitle: css`
    padding-bottom: 30px;
    max-width: 570px;
  `,
};
