// src/components/Button.js

/**
 * 汎用的なボタンコンポーネント
 *
 * このボタンコンポーネントは、アプリケーション全体で再利用可能なUI要素です。
 * 'primary', 'secondary', 'google', 'facebook', 'twitter' などの異なるスタイルバリアントをサポートしています。
 * 各バリアントは、特定のデザインガイドラインに基づいてスタイルが適用されます。
 * レスポンシブデザインに対応し、デスクトップとモバイルで適切な表示を提供します。
 */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

/**
 * Buttonコンポーネント
 *
 * @param {object} props - ボタンのプロパティ
 *  * @param {nodstringe} props.style - ボタンのスタイル
 * @param {node} props.children - ボタンのラベルまたはコンテンツ
 * @param {string} props.variant - ボタンのスタイルバリアント
 * @param {boolean} props.disabled - ボタンが無効状態かどうか
 * @param {string} props.type - ボタンのHTMLタイプ（'submit', 'button', 'reset'）
 * @param {string} props.size - ボタンのサイズ（'small', 'medium', 'large'）
 * @param {function} props.onClick - クリック時に実行される関数
 */
function Button({ style, children, variant, disabled, type, size, textAlign, icon, rightIcon, onClick, ...props }) {
  // 'icon'バリアントではサイズを指定しない
  if (variant === 'icon') size = 'null';
  if (variant === 'menu') size = 'null';
  if (variant === 'listItemDefault') size = 'null';
  if (variant === 'listItemSelected') size = 'null';
  if (variant === 'close') size = 'null';

  return (
    <button
      css={[buttonStyle, variantStyle[variant], sizeStyle[size], textAlignStyle[textAlign], style]} // スタイルの適用
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...props}
    >
      {icon && <span css={iconContainerStyle}>{icon}</span>}
      {children}
      {rightIcon && <span css={rightIconContainerStyle}>{rightIcon}</span>}
    </button>
  );
}

// プロパティタイプの定義
Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'google',
    'facebook',
    'twitter',
    'icon',
    'menu',
    'listItemDefault',
    'listItemSelected',
    'text',
    'close',
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  size: PropTypes.oneOf(['null', 'minimum', 'small', 'medium', 'large']),
  textAlign: PropTypes.oneOf(['null', 'left', 'center', 'right']),
  icon: PropTypes.element,
  rightIcon: PropTypes.element,
  onClick: PropTypes.func,
};

// デフォルトプロパティの定義
Button.defaultProps = {
  variant: 'primary',
  disabled: false,
  type: 'button',
  size: 'medium',
  textAlign: 'null',
  icon: null,
  rightIcon: null,
};

export default Button;

// ... スタイル定義
const buttonStyle = css`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between; // 左端と右端に要素を配置
  gap: 8px; // iconとtextの間隔
  background-color: transparent;
  font-size: 16px;
  padding: 10px;

  &:hover {
    background-color: transparent;
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    background-color: transparent;
  }
`;

const variantStyle = {
  primary: css`
    background-color: #003366; // ネイビーブルーに設定
    color: #fff;
    &:hover {
      background-color: #004080; // ホバー時に少し明るいネイビーブルーに設定
    }
    &:focus {
      background-color: #004080; // フォーカス時に少し明るいネイビーブルーに設定
    }
  `,
  secondary: css`
    background-color: #fff; // 未設定
    color: #fff; // 未設定
    &:hover {
      background-color: transparent; // 未設定
    }
    &:focus {
      background-color: transparent; // 未設定
    }
  `,
  google: css`
    background-color: #db4437;
    color: #fff;
    &:hover {
      background-color: #c23121;
    }
    &:focus {
      background-color: #c23121;
    }
  }
  `,
  facebook: css`
    background-color: #3b5998;
    color: #fff;
    &:hover {
      background-color: #2d4373;
    }
    &:focus {
      background-color: #2d4373;
    }
  `,
  twitter: css`
    background-color: #1da1f2;
    color: #fff;
    &:hover {
      background-color: #0c85d0;
    }
    &:focus {
      background-color: #0c85d0;
    }
  `,
  icon: css`
    background-color: transparent;
  `,
  menu: css`
    // background-color: #f4f4f8; // Salesforceのメニュー背景色
    position: relative;
    width: 100%;
    overflow: hidden;
    color: #757171; // Salesforceのメニューテキスト色
    padding: 12px 24px; // パディングを調整
    border-radius: 6px; // ボーダーの角丸を調整
    // border: 1px solid #d8dde6; // ボーダー色を調整
    font-size: 0.875rem; // フォントサイズを調整
    font-weight: 400; // フォントの太さを調整
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 10px; // アイコンとテキストの間隔を調整
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      color: #003366;
    }

    &:hover span {
      color: #16325c;
    }

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #eaf5fe;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: -1;
    }

    &:focus {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      border-color: #003366; // ホバー時のボーダー色を調整
    }

    &:active {
      background-color: #eaf5fe; // アクティブ時の背景色を調整
      border-color: #003366; // アクティブ時のボーダー色を調整
    }
  `,
  listItemDefault: css`
    position: relative;
    overflow: hidden;
    color: #1e1e1e;
    background-color: #f1f1f1;
    padding: 10px 20px;
    margin-bottom: 5px;
    border-radius: 6px; // ボーダーの角丸を調整
    font-size: 0.875rem; // フォントサイズを調整
    font-weight: 400; // フォントの太さを調整
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 10px; // アイコンとテキストの間隔を調整
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      color: #003366;
    }

    &:hover span {
      color: #16325c;
    }

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #eaf5fe;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: -1;
    }

    &:focus {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      border-color: #003366; // ホバー時のボーダー色を調整
    }

    &:active {
      background-color: #eaf5fe; // アクティブ時の背景色を調整
      border-color: #003366; // アクティブ時のボーダー色を調整
    }
  `,
  listItemSelected: css`
    position: relative;
    overflow: hidden;
    color: #003366;
    background-color: #eaf5fe;
    padding: 10px 20px;
    margin-bottom: 5px;
    border-radius: 6px; // ボーダーの角丸を調整
    font-size: 0.875rem; // フォントサイズを調整
    font-weight: 400; // フォントの太さを調整
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 10px; // アイコンとテキストの間隔を調整
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      color: #003366;
    }

    &:hover span {
      color: #16325c;
    }

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #eaf5fe;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: -1;
    }

    &:focus {
      background-color: #eaf5fe; // ホバー時の背景色を調整
      border-color: #003366; // ホバー時のボーダー色を調整
    }

    &:active {
      background-color: #eaf5fe; // アクティブ時の背景色を調整
      border-color: #003366; // アクティブ時のボーダー色を調整
    }
  `,
  text: css`
    background-color: transparent;
    color: #003366; // この色はリンクテキストに適用する色です
    padding: 0;
    height: auto;
    &:hover {
      background-color: transparent;
      text-decoration: underline; // ホバー時に下線を表示
    }
    &:focus {
      outline: none; // フォーカス時のアウトラインを非表示にする
    }
    // 他に必要なスタイルがあれば追加する
  `,
  close: css`
    font-size: 30px;
  `,
};

const sizeStyle = {
  null: css``,
  minimum: css`
    min-width: 100px;
  `,
  small: css`
    min-width: 250px;
  `,
  medium: css`
    min-width: 300px;
  `,
  large: css`
    min-width: 350px;
  `,
};

const textAlignStyle = {
  null: css``,
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
    display: block;
  `,
  right: css`
    text-align: right;
  `,
};

// iconContainerのスタイル
const iconContainerStyle = css`
  color: #16325c; // アイコンの色を調整
  font-size: 16px; // アイコンのサイズを調整
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; // アイコンとテキストの間隔を調整
  border-radius: 50%; // アイコンを丸く囲む
  width: 32px; // アイコンコンテナの幅
  height: 32px; // アイコンコンテナの高さ
  background-color: #fff; // アイコンの背景色を白に設定
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); // 軽い影を追加
`;
const rightIconContainerStyle = css`
  color: #16325c; // アイコンの色を調整
  font-size: 16px; // アイコンのサイズを調整
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; // 自動的に左の余白を最大化して右に寄せる
`;
