import React, { useState, useEffect, forwardRef } from 'react';
import { css } from '@emotion/react';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import Typography from '../shared/Typography';
import QAListItem from './QAListItem';
import Pagination from '../shared/paging/Pagination';
import ItemsPerPageSelector from '../shared/paging/ItemsPerPageSelector';
import usePagination from '../../hooks/shared/usePagination';
import useWindowWidth from '../../hooks/shared/useWindowWidth';

const QAList = forwardRef(({ searchQuery, categoryId, listTitle, excludeQuestionId, onLoadingComplete }, ref) => {
  const [qas, setQAs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const windowWidth = useWindowWidth(); // ウィンドウ幅を取得
  const isMobile = windowWidth <= 768; // モバイルビューかどうか判断
  const [itemsPerPage, setItemsPerPage] = useState(isMobile ? qas.length : 5); // モバイルでは全QAを表示、それ以外では5

  useEffect(() => {
    // ウィンドウ幅が変更されたら itemsPerPage を更新
    setItemsPerPage(isMobile ? qas.length : 5);
  }, [isMobile, qas.length]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchQAs = async () => {
      try {
        const params = searchQuery ? { search: searchQuery } : { category_id: categoryId };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/qa/question_answer/questions`, { params });
        if (isMounted) {
          const filteredQAs = excludeQuestionId
            ? response.data.filter((qa) => qa.id !== excludeQuestionId)
            : response.data;
          setQAs(filteredQAs);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError('Failed to fetch data');
          setQAs([]);
        }
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    fetchQAs();

    return () => {
      isMounted = false;
    };
  }, [searchQuery, categoryId, excludeQuestionId]);

  // usePaginationフックの使用
  const { currentPage, next, prev, jump, currentItems, getPageNumbers } = usePagination(qas, itemsPerPage);
  const pageNumbers = getPageNumbers(); // ページ番号の配列を取得

  if (isLoading) {
    return (
      <div css={styles.loader}>
        <BeatLoader color="#004c98" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div ref={ref} css={styles.listContainer}>
      <Typography variant="text" style={styles.listTitle}>
        {listTitle}
      </Typography>
      <div css={styles.pagingControls}>
        <Pagination
          style={styles.pagination}
          currentPage={currentPage}
          onNextPage={next}
          onPrevPage={prev}
          onJumpPage={jump}
          pageNumbers={pageNumbers} // 修正された部分
        />
        <ItemsPerPageSelector
          style={styles.itemsPerPageSelector}
          options={[5, 10, 20]}
          value={itemsPerPage}
          onChange={setItemsPerPage} // setItemsPerPage はページングの状態を更新する関数
        />
      </div>
      {currentItems().map((qa) => (
        <QAListItem key={qa.id} questionId={qa.id} question={qa.question} answer={qa.answer} />
      ))}
    </div>
  );
});

const styles = {
  listContainer: css``,
  listTitle: css`
    margin-bottom: 10px;
  `,
  loader: css`
    display: flex;
    justify-content: center;
    padding: 50px;
  `,
  pagingControls: css`
    position: relative;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      display: none; // モバイルビューでは非表示
    }
  `,
  pagination: css`
    position: absolute;
  `,
  itemsPerPageSelector: css`
    position: absolute;
    right: 0;
  `,
};

export default QAList;
