// src/components/Form.js

/**
 * Formコンポーネント
 *
 * 汎用的なフォームコンテナで、バリデーションやフォーム状態管理機能を提供します。
 * onSubmit プロパティには、フォームの送信時に実行する関数を渡します。
 */

import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const formStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Form = ({ children, style, onSubmit }) => {
  return (
    <form css={[formStyle, style]} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
