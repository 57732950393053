import React from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../shared/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserShield, faComments, faQuestionCircle, faBuilding } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const userSession = useSelector((state) => state.userSession);
  const navigate = useNavigate();

  const handleNavigate = (e, path) => {
    e.preventDefault(); // デフォルトの遷移を防ぐ
    if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <div css={styles.footer}>
      <div css={styles.footerMenu}>
        <Button variant="menu" onClick={(e) => handleNavigate(e, '/')} icon={<FontAwesomeIcon icon={faHome} />}>
          ホーム
        </Button>
        <Button
          variant="menu"
          onClick={(e) => handleNavigate(e, 'https://storys-lab.com/')}
          icon={<FontAwesomeIcon icon={faBuilding} />}
        >
          運営会社
        </Button>
        <Button
          variant="menu"
          onClick={(e) => handleNavigate(e, '/privacy-policy')}
          icon={<FontAwesomeIcon icon={faUserShield} />}
        >
          プライバシーポリシー
        </Button>
        <Button
          variant="menu"
          onClick={(e) => handleNavigate(e, '/qa/home')}
          icon={<FontAwesomeIcon icon={faQuestionCircle} />}
        >
          よくあるQA
        </Button>

        {userSession.isUserAuthenticated && (
          <Button
            variant="menu"
            onClick={(e) => handleNavigate(e, '/ai-chat')}
            icon={<FontAwesomeIcon icon={faComments} />}
          >
            自分で相談する
          </Button>
        )}
      </div>
    </div>
  );
};

const styles = {
  footer: css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    background-color: #f8f9fa;
    border-top: 1px solid #e7e7e7;
  `,
  footerMenu: css`
    padding: 10px 10px;
    width: 350px; /* メニューの幅を広げる */
    display: flex;
    flex-direction: column;
    z-index: 1000; /* 他の要素の上に表示 */
  `,
  link: css`
    color: #333;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 50px;

    &:hover {
      text-decoration: underline;
    }
  `,
  faIcon: css`
    color: #16325c; // FontAwesomeアイコンの色をSalesforceのメニューに合わせます
    font-size: 1.25rem; // アイコンのサイズを調整
  `,
};

export default Footer;
