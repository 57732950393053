import React from 'react';
import { css } from '@emotion/react';
import Dropdown from '../Dropdown';

const ItemsPerPageSelector = ({ style, options, value, onChange }) => {
  // Dropdown に渡すための選択肢を準備
  const dropdownOptions = options.map((option) => ({
    value: option,
    label: `${option}件表示`,
  }));

  return (
    <div css={[styles.container, style]}>
      {/*
      <label htmlFor="items-per-page" css={styles.label}>
        表示件数:
      </label>
      */}
      <Dropdown
        selectedValue={value}
        onChange={(e) => onChange(Number(e.target.value))}
        options={dropdownOptions}
        styles={styles.dropdown} // 必要に応じてカスタムスタイルを適用
      />
    </div>
  );
};

export default ItemsPerPageSelector;

const styles = {
  container: css`
    display: flex;
    align-items: center;
    margin: 10px;
  `,
  label: css`
    margin-right: 10px;
  `,
  dropdown: css`
    width: auto;
    height: 40px;
    margin-right: 10px;

    select {
      height: 40px;
    }

    ::before {
      height: 40px;
      width: 40px;
    }
    ::after {
      right: 20px;
    }
  `,
};
