// src/components/QACategoryCircularCard.js

import React from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import categoriesConfig from '../../config/categoriesConfig';

function QACategoryCircularCard({ category, onClick }) {
  const icon = categoriesConfig[category.name]?.icon || categoriesConfig['default'].icon;

  return (
    <div css={styles.card} onClick={onClick}>
      <div css={styles.iconContainer}>
        <FontAwesomeIcon icon={icon} size="3x" />
      </div>
      <div css={styles.content}>
        <div css={styles.title}>{category.name}</div>
        {/* 丸形カードでは説明文を表示しない場合がある */}
      </div>
    </div>
  );
}

const styles = {
  card: css`
    height: 100px;
    width: 100px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #eaeaea;
    border-radius: 50%; // Circle shape

    cursor: pointer;
    background-color: #fff;
    transition: box-shadow 0.3s, transform 0.3s;
    text-align: center;

    &:hover {
      transform: none; // Disable hover effect on mobile
      border: 2px solid #f60;
    }
  `,
  logo: css`
    width: 80px; // Adjust as needed
    height: auto;
    margin-bottom: 16px; // Adjust as needed
  `,
  iconContainer: css`
    color: #2c3e50;
    font-size: 10px; // Adjust font size for mobile
    height: 50px; // Auto height for mobile
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  icon: css`
    color: #2c3e50; // アイコンの色
    margin-bottom: 16px; // アイコンの下部のマージン
    // アイコンサイズはFontAwesomeIconのsize属性で制御するため、ここでは指定しない
  `,
  content: css`
    margin: 0;
  `,
  title: css`
    font-size: 12px; // Adjust font size for mobile
    color: #222;
    background-color: transparent; // No background for mobile
    padding: 0 4px;
    height: 32px;
    line-height: 16px;
    width: 90px; // Width of the title on mobile
    min-width: 90px;
    margin-bottom: 0; // No bottom margin on mobile
    display: inline-block;
    border-radius: 25px;
  `,
  // Other styles...
};

export default QACategoryCircularCard;
