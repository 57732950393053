// src/components/NavigationBar.js

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import axios from 'axios'; // axios をインポート
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // Redux の useSelector と useDispatch フックをインポート
import { userLogout } from '../../actions/userSession'; // adminLogout アクションをインポート
import Button from '../shared/button/Button';
import Logo from '../internal/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faUsers,
  faQuestionCircle,
  faChevronLeft,
  faChevronRight,
  faFileCircleQuestion,
  faSignOutAlt,
  faSignInAlt,
  faSearch,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'; // FontAwesomeのアイコンをインポート
import categoriesConfig from '../../config/categoriesConfig';

const NavigationBar = () => {
  // メニューが開いているかの状態
  const [isMenuOpen, setMenuOpen] = useState(false);
  // 表示するメニューの内容
  const [menuContent, setMenuContent] = useState('main'); // 'main' または 'categories'
  // カテゴリのデータを保持する状態
  const [categories, setCategories] = useState([]);
  // Redux の useDispatchフック
  const dispatch = useDispatch();
  // Redux ストアからログイン状態を取得
  const userSession = useSelector((state) => state.userSession);

  // カテゴリデータをAPIから取得するための副作用
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/qa/category/categories?level=1`);

        setCategories(response.data); // 取得したデータを状態にセット
      } catch (error) {
        console.error('カテゴリの取得に失敗しました', error);
      }
    };

    // 'categories'メニューが選択されたときにカテゴリデータを取得
    if (menuContent === 'categories') {
      fetchCategories();
    }
  }, [menuContent]); // menuContentが変更されるたびに実行

  // メニュー外クリック時にメニューを閉じるための副作用
  useEffect(() => {
    const closeMenu = (event) => {
      // クリックされた要素がナビゲーションバーの外であり、かつメニューが開いている場合
      if (!event.target.closest('#navbar') && isMenuOpen) {
        setMenuOpen(false); // メニューを閉じる
      }
    };

    // クリックイベントリスナーをドキュメントに追加
    document.addEventListener('click', closeMenu);
    return () => {
      // コンポーネントのクリーンアップ時にイベントリスナーを削除
      document.removeEventListener('click', closeMenu);
    };
  }, [isMenuOpen]); // isMenuOpenが変更されるたびに実行

  // useNavigate フックを使用して navigate 関数を取得
  const navigate = useNavigate();

  // メニューの項目がクリックされたときのハンドラ
  const handleMenuItemClick = (e, content, categoryId = null) => {
    e.preventDefault();
    e.stopPropagation();

    // content に応じて異なるページにナビゲーション
    if (content === 'main') {
      setMenuContent(content); // メニュー内容を 'main' に切り替え
      //setMenuOpen(true); // メニューを開いたままにする
    } else if (content === 'home') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/'); // ホームページに遷移
    } else if (content === 'privacy') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/privacy-policy'); // ホームページに遷移
    } else if (content === 'login') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/login'); // ログインページに遷移
    } else if (content === 'logout') {
      setMenuOpen(false); // メニューを閉じる
      dispatch(userLogout()); // Reduxのログアウトアクションを実行
      navigate('/login'); // ログインページに遷移
    } else if (content === 'categories') {
      setMenuContent(content); // メニュー内容を 'categories' に切り替え
      //setMenuOpen(true); // メニューを開いたままにする
    } else if (content === 'ai-chat') {
      if (userSession.isUserAuthenticated) {
        setMenuOpen(false); // メニューを閉じる
        navigate('/ai-chat'); // 自分で相談ページに遷移
      } else {
        alert('自分で相談を始めるにはログインが必要です。');
      }
    } else if (content === 'qa') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/qa/home'); // よくあるQAページに遷移
    } else if (content === 'qasearch') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/qa/search'); // よくあるQAキーワード検索ページに遷移
    } else if (content === 'category') {
      setMenuOpen(false); // メニューを閉じる
      navigate(`/qa/category/${categoryId}`); // 特定のカテゴリページに遷移
    }
  };

  // メニュートグルのハンドラ
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen); // メニューの開閉状態をトグル
  };

  // ロゴクリック時のハンドラ
  const handleLogoClick = () => {
    navigate('/'); // ホームページに遷移
  };

  // ナビゲーションバーのレンダリング
  return (
    <nav css={styles.navBar} id="navbar">
      <Logo alt="Logo" style={[styles.logoStyle, styles.logoHover]} onClick={handleLogoClick} />
      {/* メニュー開閉ボタン */}
      <Button
        variant="icon"
        onClick={handleMenuToggle}
        icon={<FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} css={styles.faIcon} />}
      />
      {/* メニューが開いている場合にメニュー内容を表示 */}
      {isMenuOpen && (
        <div css={styles.navMenu}>
          <div css={styles.scrollContainer}>
            {/* スクロールさせたいコンテンツ */}
            {/* カテゴリメニューが表示されている場合にのみ「戻る」ボタンを表示 */}
            {menuContent === 'categories' && (
              <>
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'main')}
                  icon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                  戻る
                </Button>
                {/* 「よくあるQA」タイトルを表示 */}
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'qa')}
                  icon={<FontAwesomeIcon icon={faFileCircleQuestion} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  よくあるQAホーム
                </Button>
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'qasearch')}
                  icon={<FontAwesomeIcon icon={faSearch} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  キーワード検索
                </Button>
              </>
            )}
            {/* メニュー内容に基づいて表示を切り替え */}
            {menuContent === 'main' ? (
              // メインメニューの内容
              <>
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'home')}
                  icon={<FontAwesomeIcon icon={faUsers} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  ホーム
                </Button>
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'privacy')}
                  icon={<FontAwesomeIcon icon={faUserShield} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  プライバシーポリシー
                </Button>
                {userSession.isUserAuthenticated ? (
                  // ログイン済みの場合、ログアウトメニューを表示
                  <Button
                    variant="menu"
                    onClick={(e) => handleMenuItemClick(e, 'logout')}
                    icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                  >
                    ログアウト
                  </Button>
                ) : (
                  // 未ログインの場合、ログインメニューを表示
                  <Button
                    variant="menu"
                    onClick={(e) => handleMenuItemClick(e, 'login')}
                    icon={<FontAwesomeIcon icon={faSignInAlt} />}
                  >
                    ログイン
                  </Button>
                )}
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'categories')}
                  icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  よくあるQA
                </Button>
                <Button
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'ai-chat')}
                  icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  自分で相談する
                </Button>
              </>
            ) : (
              // カテゴリメニューの内容
              categories.map((category) => (
                <Button
                  key={category.id}
                  variant="menu"
                  onClick={(e) => handleMenuItemClick(e, 'category', category.id)}
                  icon={
                    <FontAwesomeIcon icon={categoriesConfig[category.name]?.icon || categoriesConfig['default'].icon} />
                  }
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  {category.name}
                </Button>
              ))
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

const styles = {
  navBar: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between; // 要素を左右両端に配置
    align-items: center; // 中央揃え
    padding: 0 20px;
    background-color: transparent;
  `,
  logoStyle: css`
    width: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: opacity 0.3s; // 透明度の変化にトランジションを追加
    cursor: pointer;
  `,
  logoHover: css`
    &:hover {
      opacity: 0.5; // ホバー時に透明度を変更
    }
  `,
  menuIcon: css`
    cursor: pointer;
    font-size: 24px;
    color: white; /* アイコンの色 */
  `,
  navMenu: css`
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 350px; /* メニューの幅を広げる */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 1000; /* 他の要素の上に表示 */
  `,
  scrollContainer: css`
    max-height: 400px; /* コンテナの最大高さ */
    overflow-y: auto; /* 縦方向のスクロールバーを表示 */
  `,
  navItem: css`
    width: 100%;
  `,
  faIcon: css`
    color: #16325c; // FontAwesomeアイコンの色をSalesforceのメニューに合わせます
    font-size: 1.25rem; // アイコンのサイズを調整
  `,
};

export default NavigationBar;
