// src/reducers/userProfile.js

import {
  USER_PROFILE_GET,
  USER_PROFILE_GET_SUCCESS,
  USER_PROFILE_GET_FAILURE,
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILURE,
} from '../actions/types';

const initialState = {
  profile: {
    userId: null,
    username: null,
    email: null,
    phoneNumber: null,
    postalCodeFirst: null,
    postalCodeSecond: null,
    prefecture: null,
    city: null,
    district: null,
    building: null,
    roomNumber: null,
  },
  loading: false,
  error: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_GET:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILE_GET_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case USER_PROFILE_GET_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case USER_PROFILE_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
        updateSuccess: true,
        error: null,
      };
    case USER_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        updateSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
