// src/pages/QAHomePage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { css } from '@emotion/react';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import Typography from '../components/shared/Typography';
import QACategorySquareCard from '../components/qa/QACategorySquareCard';
import QACategoryCircularCard from '../components/qa/QACategoryCircularCard';
import FloatingActionButton from '../components/shared/button/FloatingActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchModal from '../components/shared/modal/SearchModal';

function QAHomePage() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/qa/category/categories?level=1`);
        setCategories(response.data);
      } catch (error) {
        console.error('カテゴリの取得に失敗しました', error);
      }
    };

    fetchCategories();
  }, []);

  // [モバイル用カテゴリーセクション]選択されたカテゴリーIDをトラックするための状態
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // [モバイル用カテゴリーセクション]カテゴリー選択のハンドラー
  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(selectedCategoryId === categoryId ? null : categoryId);
  };

  // [モバイル用カテゴリーセクション]選択されたカテゴリーを取得するヘルパー関数
  const getSelectedCategory = () => {
    return categories.find((category) => category.id === selectedCategoryId);
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <div css={styles.introSection}>
          <Typography variant="title" style={styles.headerTitle}>
            カテゴリーからQAを探します
          </Typography>
          {/* Comment out Description and SearchBar */}
          {/* 
          <Typography variant="text" style={styles.headerDescription}>
            当サイトのよくある質問カテゴリーをご覧いただけます。ご不明な点があれば、検索機能をご利用ください。
          </Typography>
          <div css={styles.searchSection}>
            <QASearchBar onSearch={handleSearch} />
          </div> 
          */}
          {/* Comment out the usageManual section after the search bar */}
          {/* 
          <div css={styles.usageManualSection}>
            <QAUsageManual />
          </div>
           */}
        </div>
        <div css={styles.categorySectionContainer}>
          {/* 
          <Typography variant="title" style={styles.categorySectionTitle}>
            カテゴリーを選んでください
          </Typography>
          */}
          {/* デスクトップ用カテゴリーセクション */}
          <div css={styles.categorySectionDesktop}>
            {categories.map((category) => (
              <QACategorySquareCard key={category.id} category={category} />
            ))}
          </div>

          {/* モバイル用カテゴリーセクション */}
          <div css={styles.categorySectionMobile}>
            {/* 横スクロールするカテゴリーリスト */}
            <div css={styles.categorySectionMobileScrollContainer}>
              {categories.map((category) => (
                <QACategoryCircularCard
                  key={category.id}
                  category={category}
                  onClick={() => handleCategorySelect(category.id)}
                />
              ))}
            </div>
            {/* 選択されたカテゴリーの詳細 */}
            {selectedCategoryId && (
              <div css={styles.categorySectionMobileSelectedCategoryContainer}>
                <QACategorySquareCard category={getSelectedCategory()} />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 検索モーダル */}
      <FloatingActionButton tooltip="検索はこちら" onClick={() => setIsOpenSearchModal(true)}>
        <FontAwesomeIcon icon={faSearch} />
      </FloatingActionButton>
      <SearchModal
        isOpen={isOpenSearchModal}
        onClose={() => setIsOpenSearchModal(false)}
        onSearch={(keyword) => {
          setIsOpenSearchModal(false);
          // 検索結果ページへの遷移ロジック
          navigate(`/qa/search?query=${encodeURIComponent(keyword)}`);
        }}
        placeholder="キーワードを入力して質問を検索します"
      />
      <Footer />
    </div>
  );
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-size: cover;
    background-position: center;
    min-height: 100vh; // Adjust the height as needed
  `,
  introSection: css`
    text-align: center;
    padding: 30px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    // usageManualSection 廃止に伴うマージン調整
    // margin-bottom: 40px;
    width: 100%;
    max-width: 1085px;
    box-sizing: border-box;
    // モバイルデバイス用のスタイル
    @media (max-width: 768px) {
      padding: 10px;
      padding-top: 70px;
    }
  `,
  headerTitle: css`
    margin: 0;
    margin-bottom: 10px;
  `,
  headerDescription: css`
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
  `,
  searchSection: css`
    margin-bottom: 50px;
  `,
  categorySectionContainer: css`
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
  `,
  categorySectionTitle: css`
    padding-top: 40px;
    margin: 0 auto; /* Center the grid */
    text-align: center;
  `,
  categorySectionDesktop: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 40px;
    padding: 40px;
    border-radius: 10px;

    @media (max-width: 768px) {
      display: none; // モバイルビューでは非表示
    }
  `,
  categorySectionMobile: css`
    display: none; // デフォルトでは非表示

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      margin-bottom: 100px;
    }
  `,
  categorySectionMobileScrollContainer: css`
    display: flex;
    overflow-x: auto;
    grid-gap: 20px;
    padding: 20px 0;
  `,
  categorySectionMobileSelectedCategoryContainer: css`
    display: flex;
    justify-content: center; // 中央寄せ
    padding: 20px;
  `,
  // その他のスタイルをここに追加
};

export default QAHomePage;
