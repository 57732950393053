import React from 'react';
import { css } from '@emotion/react';

const TermsOfService = () => {
  return (
    <div css={styles.container}>
      <h2>【AI税務相談サービス利用規約_有料版】</h2>
      <h3>第1条（規約の適用）</h3>
      <p>
        1.
        株式会社Story's（以下「当社」といいます。）は、本サービス（次条第1号で定義します。）の提供条件および本サービスの利用に関する当社と利用者（次条第2号で定義します。）との間の権利義務関係を定めることを目的として、「AI税務相談サービス利用規約_有料版」（以下「本規約」といいます。）を定めるものとし、本規約は、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。
      </p>
      <p>2. 当社が当社ウェブサイト上で掲載する本サービス利用に関するルールは、本規約の一部を構成するものとします。</p>
      <p>
        3.
        本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
      </p>

      <h3>第2条（定義）</h3>
      <p>
        本規約における用語の定義は以下の通りです。
        <br />
        (1) 「本サービス」：AIを用いて税に関する一般的な情報提供を行うAI税務相談サービス有料版をいいます。
        <br />
        (2) 「利用者」：当サービスを申し込み、利用する全てのお客様をいいます。
      </p>

      <h3>第3条（本サービスの内容）</h3>
      <p>
        1. 本サービスは、以下の機能を月額5,000円（消費税別）で提供します：
        <br />
        (1) ログイン機能
        <br />
        (2) よくあるQA一覧
        <br />
        (3) 自分で相談する機能
        <br />
        (4) How to AI
      </p>
      <p>
        2.
        税理士による直接相談を希望する場合は、別途料金が発生します。詳細は当社ウェブサイトまたはお問い合わせ先でご確認ください。
      </p>

      <h3>第4条（登録）</h3>
      <p>
        1.
        本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の利用を申請することができます。
      </p>
      <p>2. 利用者は、本サービスの利用申請時に、次条に定める方法により利用料の決済を行うものとします。</p>
      <p>
        3.
        当社は、当社の基準に従って、第１項に基づいて利用申請を行った利用希望者（以下「利用申請者」といいます。）の利用の可否を判断し、当社が利用を認める場合にはアカウント情報を利用申請者に通知します。利用申請者の利用者としての登録は、当社が本項の通知を行ったことをもって完了したものとします。
      </p>
      <p>
        4.
        当社は、利用申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
        <br />
        (1) 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
        <br />
        (2)
        未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
        <br />
        (3)
        反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じです。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
        <br />
        (4) 過去当社との契約に違反した者またはその関係者であると当社が判断した場合
        <br />
        (5) 第11条に定める措置を受けたことがある場合
        <br />
        (6) その他、登録を適当でないと当社が判断した場合
      </p>

      <h3>第5条（決済方法）</h3>
      <p>決済方法は、LINE決済サービス、クレジットカード決済、銀行振込から選択するものとします。</p>

      <h3>第6条（本サービスの利用期間）</h3>
      <p>
        1.
        本サービスの利用期間は、利用開始日から1か月とし、以降は1か月ごとに自動更新され、利用者が選択した決済方法で自動的に利用料が決済されます。
      </p>
      <p>2. 自動更新は利用開始日に基づき行われ、月の末日が利用開始日の場合はその月の最終日を更新日とします。</p>
      <p>
        3.
        本サービスの自動更新を停止したい場合、更新日の5日前までに、当社のウェブサイト上でキャンセル手続きを行う必要があります。
      </p>

      <h3>第7条（利用者の責任）</h3>
      <p>1. 利用者は、当社に提供する情報が正確、完全かつ最新であることを確認するものとし、これを保証します。</p>
      <p>
        2.
        利用者は、本サービスへの登録内容に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
      </p>
      <p>
        3.
        利用者は、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
      </p>
      <p>
        4.
        パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は利用者が負うものとします。
      </p>

      <h3>第8条（保証の否認及び免責）</h3>
      <p>
        1.
        本サービスは一般的な情報提供を目的としており、最終的な税務判断は利用者の自己責任で行うものとし、当該判断により利用者に生じた結果について、当社の責に帰すべき事由がある場合を除き、当社は一切の責任を負いません。
      </p>
      <p>
        2.
        当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
      </p>
      <p>
        3.
        当社は、本サービスに関して利用者が被った損害につき賠償する責任を負う場合、過去1か月間に利用者が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負いません。
      </p>
      <p>
        4.
        本サービスまたは当社ウェブサイトに関連して利用者と他の利用者または第三者との間において生じた取引、連絡、紛争等については、当社は一切関与しないものとし、利用者が自己の責任によって解決するものとします。
      </p>

      <h3>第9条（禁止行為）</h3>
      <p>
        利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
        <ol>
          <li>法令に違反する行為または犯罪行為に関連する行為</li>
          <li>当社、本サービスの他の利用者またはその他の第三者に対する詐欺、誹謗中傷する行為または脅迫行為</li>
          <li>公序良俗に反する行為</li>
          <li>
            当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
          </li>
          <li>
            本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスまたは本サービスを通じて相談する税理士等に送信すること
            <ul>
              <li>過度に暴力的または残虐な表現を含む情報</li>
              <li>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</li>
              <li>当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</li>
              <li>過度にわいせつな表現を含む情報</li>
              <li>差別を助長する表現を含む情報</li>
              <li>自殺、自傷行為を助長する表現を含む情報</li>
              <li>薬物の不適切な利用を助長する表現を含む情報</li>
              <li>反社会的な表現を含む情報</li>
              <li>チェーンメール等の第三者への情報の拡散を求める情報</li>
              <li>他人に不快感を与える表現を含む情報</li>
            </ul>
          </li>
          <li>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</li>
          <li>当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>当社のネットワークまたはシステム等への不正アクセス</li>
          <li>第三者に成りすます行為</li>
          <li>本サービスの他の利用者のIDまたはパスワードを利用する行為</li>
          <li>当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
          <li>本サービスの他の利用者の情報の収集</li>
          <li>当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</li>
          <li>当社ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為</li>
          <li>反社会的勢力等への利益供与</li>
          <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
          <li>前各号の行為を試みること</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </p>

      <h3>第10条（本サービスの停止等）</h3>
      <p>
        当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
        <ol>
          <li>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</li>
          <li>
            コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
          </li>
          <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
          <li>その他、当社が停止または中断を必要と判断した場合</li>
        </ol>
      </p>

      <h3>第11条（登録抹消等）</h3>
      <p>
        当社は、利用者が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、当該利用者について本サービスの利用を一時的に停止し、または利用者としての登録を抹消することができます。
        <ol>
          <li>本規約のいずれかの条項に違反した場合</li>
          <li>登録事項に虚偽の事実があることが判明した場合</li>
          <li>
            支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
          </li>
          <li>6か月以上本サービスの利用がない場合</li>
          <li>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</li>
          <li>第4条第4項各号に該当する場合</li>
          <li>その他、当社が本サービスの利用または利用者としての登録の継続を適当でないと判断した場合</li>
        </ol>
      </p>

      <h3>第12条（退会）</h3>
      <p>
        利用者は、当社所定の手続の完了により、本サービスから退会し、自己の利用者としての登録を抹消することができます。退会にあたり、当社に対して負っている債務が有る場合は、利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </p>

      <h3>第13条（本サービスの内容の変更、終了）</h3>
      <p>
        当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。当社が本サービスの提供を終了する場合、当社は利用者に事前に通知するものとします。
      </p>

      <h3>第14条（個人情報の取扱い）</h3>
      <p>
        当社は、利用者から提供された個人情報を厳重に管理し、当社が別途定めるプライバシーポリシーに従って個人情報を取り扱います。
      </p>

      <h3>第15条（権利帰属）</h3>
      <p>
        当社ウェブサイトおよび本サービスに関する知的財産権（プログラム、データ、画像、文章等に関する一切の権利）は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
      </p>

      <h3>第16条（秘密保持）</h3>
      <p>
        利用者は、本サービスの利用に関連して知り得た当社が利用者に対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、第三者に開示または漏洩してはならないものとします。秘密に取扱うものとします。
      </p>

      <h3>第17条（連絡／通知）</h3>
      <p>
        本サービスに関する問い合わせその他利用者から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から利用者に対する連絡または通知は、当社の定める方法で行うものとします。当社が、登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、利用者は当該連絡または通知を受領したものとみなします。
      </p>

      <h3>第18条（サービス利用契約上の地位の譲渡等）</h3>
      <p>
        利用者は、当社の書面による事前の承諾なく、本サービス利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </p>

      <h3>第19条（利用規約の変更）</h3>
      <p>
        当社は、必要に応じて本規約を変更することがあります。変更後の規約は当社ウェブサイト上での公開をもって効力を発生します。
      </p>

      <h3>第20条（準拠法及び管轄裁判所）</h3>
      <p>
        本利用規約に関する紛争については、日本法を準拠法とし、当社の所在地を管轄する裁判所を専属的合意管轄裁判所とします。
      </p>

      <h3>お問い合わせ先</h3>
      <p>
        メールアドレス
        <br />
        kengo.s@storys-labo.com
      </p>
      <p>制定日：2024年5月1日</p>

      {/* 各条文に対応するセクションを続けて記述してください。 */}
    </div>
  );
};

const styles = {
  container: css`
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `,
};

export default TermsOfService;
