// src/pages/QASearchPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import SearchBar from '../components/shared/SearchBar';
import QAList from '../components/qa/QAList';
import Typography from '../components/shared/Typography';

function QASearchPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const qaListRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');
    setSearchQuery(decodeURIComponent(query || ''));
  }, [location]);

  const handleSearch = (keyword) => {
    setSearchQuery(keyword); // 検索バーからの入力をstateに設定
  };

  // スクロールを実行する関数
  const scrollToRef = (ref) => {
    // ユーザーがインタラクションした後でのみスクロールを実行
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onQAListLoadingComplete = () => {
    // ローディング完了後に行う処理
    if (searchQuery) {
      scrollToRef(qaListRef); // QAリストの先頭へスクロール
    }
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        {/* 検索バー */}
        <Typography variant="title" style={styles.pageTitle}>
          キーワードでQAを検索します
        </Typography>
        <SearchBar placeholder="キーワードでQAを検索" onSearch={handleSearch} style={styles.searchBar} />
        {/* 検索結果の表示 */}
        {searchQuery && (
          <QAList
            searchQuery={searchQuery}
            listTitle={`"${searchQuery}"の検索結果`}
            ref={qaListRef}
            onLoadingComplete={onQAListLoadingComplete}
          />
        )}
        <Footer />
      </div>
    </div>
  );
}

const styles = {
  container: css`
    padding: 20px;
  `,
  pageTitle: css`
    padding: 40px 0;
    margin: 0 auto; /* Center the grid */
    text-align: center;
  `,
  searchBar: css`
    width: 80%;
    margin-bottom: 40px;
  `,
  searchResultTitle: css`
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  `,
};

export default QASearchPage;
