import React from 'react';
import { css } from '@emotion/react';

const GuideSection = ({ step, title, description, type = 'STEP' }) => {
  return (
    <div css={styles.flowItem}>
      <p css={css(styles.icon, styles.dynamicIcon(type))}>{step}</p>
      <dl css={styles.detail}>
        <dt css={styles.title}>{title}</dt>
        <dd css={styles.description}>{description}</dd>
      </dl>
    </div>
  );
};

export default GuideSection;

const styles = {
  flowItem: css`
    display: flex;
    padding: 20px 50px;
    border-top: solid 1px #e1e8ed;
    max-width: 800px;
    width: 100%;

    &:last-child::after {
      content: none;
    }

    @media (max-width: 768px) {
      padding: 20px 0;
    }
  `,
  icon: css`
    line-height: 1;
    font-size: 2em;
    font-weight: bold;
    color: #e26b5d;
    text-align: center;
    width: 70px;
    position: relative;
    margin-top: 0;
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 40px);
      background-color: #858585;
      position: absolute;
      left: 0;
      right: 0;
      top: 60px;
      margin: auto;
    }
  `,
  dynamicIcon: (type) => css`
    &::before {
      content: '${type}'; // type に基づいて表示内容を動的に変更
      font-size: 0.3em;
      display: block;
      margin-bottom: 3px;
      letter-spacing: 1px;
    }
  `,
  detail: css`
    width: calc(100% - 70px);
    margin-top: 0.8em;
  `,
  title: css`
    font-size: 1.2em;
    line-height: 2;
    font-weight: bold;
    margin-bottom: 10px;
  `,
  description: css`
    margin: 0;
  `,
};
