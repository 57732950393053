/**
 * src/reducers/userRegistration.js
 *
 * このリデューサーは、ユーザー登録プロセスに関連するアクションの結果に基づいてアプリケーションの状態を管理します。
 * ユーザー登録、メール認証、メール確認の各プロセスの成功または失敗の状態を追跡します。
 *
 * 各アクションタイプに応じて、対応する状態（成功またはエラーメッセージ）が更新されます。
 * これにより、アプリケーションはユーザー登録の進行状況に応じて適切なフィードバックを提供することができます。
 *
 * 状態は、サインアップ成功、サインアップ失敗、メール認証成功、メール認証失敗、
 * メール確認成功、メール確認失敗の6つの主要なプロパティで構成されます。
 * これらの状態は、ユーザーインターフェースにおいてユーザーの操作を適切にガイドし、
 * エラーハンドリングを行うために使用されます。
 *
 * 使用上の注意:
 * - 各アクションクリエーターは、対応するアクションタイプとペイロードをディスパッチします。
 * - リデューサーは純粋関数であり、同じ入力に対して常に同じ出力を返します。
 * - 状態は直接変更せず、新しい状態オブジェクトを返すことによって更新します。
 */

import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAILURE,
  EMAIL_CONFIRM_SUCCESS,
  EMAIL_CONFIRM_FAILURE,
} from '../actions/types';

const initialState = {
  registrationSuccess: false,
  registrationError: null,
  emailVerifySuccess: false,
  emailVerifyError: null,
  emailConfirmSuccess: false,
  emailConfirmError: null,
};

const userRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
        registrationError: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        registrationSuccess: false,
        registrationError: action.payload,
      };
    case EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        emailVerifySuccess: true,
        emailVerifyError: null,
      };
    case EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        emailVerifySuccess: false,
        emailVerifyError: action.payload,
      };
    case EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        emailConfirmSuccess: true,
        emailConfirmError: null,
      };
    case EMAIL_CONFIRM_FAILURE:
      return {
        ...state,
        emailConfirmSuccess: false,
        emailConfirmError: action.payload,
      };
    default:
      return state;
  }
};

export default userRegistrationReducer;
