// src/components/QACategoryList.js

import React, { useState, useEffect, forwardRef } from 'react';
import { css } from '@emotion/react';
import Button from '../shared/button/Button';
import Typography from '../shared/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const QACategoryList = forwardRef(
  ({ parentCategoryId, listTitle, onCategorySelect, selectedCategory, onLoadingComplete }, ref) => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchCategories = async () => {
        try {
          if (parentCategoryId) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/qa/category/categories/${parentCategoryId}/children`
            );
            setCategories(response.data);
            setError(null);
            onLoadingComplete && onLoadingComplete(); // 親コンポーネントに読み込み完了を通知
          }
        } catch (error) {
          setError(error.message || 'データの取得に失敗しました。');
          onLoadingComplete && onLoadingComplete(); // 親コンポーネントに読み込み完了を通知
        }
      };

      fetchCategories();
    }, [parentCategoryId, onLoadingComplete]);

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <div ref={ref} css={styles.listContainer}>
        {/* カテゴリーの先頭に案内テキストを挿入 */}
        <Typography variant="text">{listTitle}</Typography>
        <div css={styles.scrollableContent}>
          {/* カテゴリーのリスト表示 */}
          {categories.map((category, index) => (
            <Button
              key={category.id}
              variant={selectedCategory === category.id ? 'listItemSelected' : 'listItemDefault'}
              css={[
                styles.categoryListItem,
                index === categories.length - 1 && styles.lastItem, // これで最後のアイテムに特別なスタイルを適用できます
              ]}
              onClick={() => onCategorySelect(category.id)}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            >
              {category.name}
            </Button>
          ))}
        </div>
        {parentCategoryId && <div css={styles.gradientOverlay} />}
      </div>
    );
  }
);

const styles = {
  listContainer: css`
    flex: 1;
    max-height: 200px;
    overflow-y: hidden;
    position: relative;
  `,
  scrollableContent: css`
    max-height: 170px; /* 親要素から gradientOverlay の高さを差し引く */
    overflow-y: auto;
    position: relative; /* 絶対位置ではなく、relativeに戻します */

    /* Webkitブラウザ用 */
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Firefox用 */
    &::-moz-scrollbar {
      width: 8px;
    }

    &::-moz-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    /* IE/Edge用 */
    &::-ms-scrollbar {
      width: 8px;
    }

    &::-ms-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
  `,
  gradientOverlay: css`
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 10px;
    height: 30px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  `,
  categoryListItem: css`
    width: 98%;
  `,
  lastItem: css`
    margin-bottom: 20px;
  `,
};

export default QACategoryList;
