import React, { useState } from 'react';
import { css } from '@emotion/react';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = (event) => {
    event.stopPropagation(); // イベントの伝播を止める
    setIsOpen(!isOpen);
  };

  return (
    <div css={styles.accordion}>
      <button type="button" onClick={toggleAccordion} css={styles.button}>
        {title}
      </button>
      {isOpen && <div css={styles.content}>{children}</div>}
    </div>
  );
};

const styles = {
  accordion: css`
    margin: 20px 0;
  `,
  button: css`
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background-color: #003366; // ネイビーブルーに設定
    color: #fff;
    transition: background-color 0.3s, transform 0.1s;
    &:hover {
      background-color: #004080; // ホバー時に少し明るいネイビーブルーに設定
    }
    &:focus {
      background-color: #004080; // フォーカス時に少し明るいネイビーブルーに設定
    }
  `,
  content: css`
    padding: 20px;
    border-top: 1px solid #ccc;
  `,
};

export default Accordion;
