/**
 * src/actions/userRegistrationEmail.js
 *
 */

import axios from 'axios';
import { REGISTRATION_EMAIL_REQUEST, REGISTRATION_EMAIL_SENT, REGISTRATION_EMAIL_FAILED } from './types';

export const sendRegistrationEmail = (userData) => {
  return async (dispatch) => {
    dispatch({ type: REGISTRATION_EMAIL_REQUEST });
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/user_registration_email/send`, userData);
      if (response.status === 200) {
        dispatch({ type: REGISTRATION_EMAIL_SENT });
        return response.data; // 正常に処理された場合はdataを返す
      } else {
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      dispatch({ type: REGISTRATION_EMAIL_FAILED, payload: error.message });
      throw error; // catchブロックでエラーを再スローして、コンポーネントのcatchブロックで捕捉できるようにする
    }
  };
};
