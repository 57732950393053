// src/components/UserRegistrationStepCard.js

import React from 'react';
import { css } from '@emotion/react';

function UserRegistrationStepCard({ step }) {
  return (
    <div css={styles.stepCard}>
      <div css={styles.numberCircle}>{step.index}</div>
      <h2 css={styles.stepTitle}>{step.title}</h2>
      <div css={styles.titleBoundaryLine}></div>
      <div css={styles.iconContainer}>
        {step.icon}
        {/*<FontAwesomeIcon icon={faQuestion} css={styles.icon} size="4x" /> {/* Adjust size as needed */}
      </div>
      <p css={styles.stepDescription}>{step.description}</p>
    </div>
  );
}

const styles = {
  stepCard: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    height: auto; /* Allow card to grow based on content */
    transition: box-shadow 0.3s, transform 0.3s;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px);
    }
  `,
  numberCircle: css`
    position: absolute;
    top: -20px; /* adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    background: #2c3e50;
    color: white;
    border-radius: 50%;
    font-size: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* to make it pop a bit */
    z-index: 10; /* to ensure it's above other elements */
  `,
  stepTitle: css`
    font-size: 1rem;
    color: #032d60;
    margin: 10px 0; // Adjusted for spacing
    @media (max-width: 1100px) {
      font-size: 1rem;
    }
  `,
  titleBoundaryLine: css`
    height: 2px; // Height of the line
    background-color: #e0e0e0; // Color of the line, adjust as needed
    width: calc(100% - 30px); // Full width minus some padding
    margin: 0 15px; // Half of the subtracted width as margin on both sides
  `,
  icon: css`
    color: #2c3e50;
    margin: 15px 0; /* Adjust spacing as needed */
  `,
  iconContainer: css`
    width: 60px; /* adjust as needed */
    height: 60px; /* adjust as needed */
    margin: 16px auto; /* centers the image and adds space above and below */
  `,
  stepDescription: css`
    font-size: 0.9rem;
    color: #666;
  `,
  // ... (add any additional styles you need)
};

export default UserRegistrationStepCard;
