// src/pages/HomePage.js

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../components/internal/Logo'; // Logoコンポーネントをインポート
import Typography from '../components/shared/Typography'; // Typographyコンポーネントをインポート
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faComments, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import GuideSection from '../components/howto/GuideSection';

function HomePage() {
  // Redux ストアからログイン状態を取得
  const userSession = useSelector((state) => state.userSession);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('professionals');

  // ログイン促進ポップアップの表示をハンドルする関数
  const handleConsultClick = (e) => {
    if (!userSession.isUserAuthenticated) {
      e.preventDefault(); // リンクのデフォルトの動作を防止
      alert('自分で相談を始めるにはログインが必要です。');
    } else {
      // ログイン済みの場合はリンクによるルーティングをそのまま実行
      navigate('/ai-chat');
    }
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.dashboardContainer}>
        <Logo alt="Logo" css={styles.logoStyle} />
        <Typography variant="title" style={styles.dashboardTitle}>
          AI税務相談
        </Typography>
        <div css={styles.navigation}>
          {/* 各ナビゲーションアイテム */}
          {/* ログイン状態に応じてログインボタンを表示 */}
          {!userSession.isUserAuthenticated && (
            <Link to="/login" css={styles.navItem}>
              <FontAwesomeIcon icon={faSignInAlt} css={styles.navIcon} />
              <Typography variant="text">ログイン</Typography>
            </Link>
          )}
          <Link to="/ai-chat" css={styles.navItem} onClick={handleConsultClick}>
            <FontAwesomeIcon icon={faComments} css={styles.navIcon} />
            <Typography variant="text">自分で相談</Typography>
          </Link>
          <Link to="/qa/home" css={styles.navItem}>
            <FontAwesomeIcon icon={faQuestionCircle} css={styles.navIcon} />
            <Typography variant="text">よくあるQA</Typography>
          </Link>
        </div>
        <div css={styles.serviceIntroductionContainer}>
          <Typography variant="title" style={styles.serviceIntroductionTitle}>
            AI税務相談の特長
          </Typography>
          <Typography variant="text" style={styles.serviceIntroductionSubtitle}>
            当サービスの特長をご紹介します。迅速かつ正確に税務に関する疑問を解決します。
          </Typography>

          <div css={styles.tabs}>
            <button onClick={() => setActiveTab('professionals')} css={styles.tab(activeTab === 'professionals')}>
              税理士または経理・税務業務に携わる方へ
            </button>
            <button onClick={() => setActiveTab('freelancers')} css={styles.tab(activeTab === 'freelancers')}>
              フリーランスまたは一般の方へ
            </button>
          </div>

          {/* 各特徴のセクション */}
          {activeTab === 'professionals' && (
            <>
              <GuideSection
                type="POINT"
                step="1"
                title="即時に回答が来る"
                description="普通は専門家に聞くと、回答までに2-3日、1週間かかることは当たり前なので、24時間365日タイムリーに回答が来るのが利用者にとって有難い。"
              />
              <GuideSection
                type="POINT"
                step="2"
                title="各カテゴリに応じた、10種類の質問テンプレートを使用することで、質問内容を的確にまとめられる"
                description="利用者の前提を整理することができ、認識の齟齬をなくせる。"
              />
              <GuideSection
                type="POINT"
                step="3"
                title="誰でも精度の高い回答が来る、専門用語を使って噛み砕ける"
                description="質問者のレベルに応じた回答が来るので、会計・税務の実務に活かせる。"
              />
              <GuideSection
                type="POINT"
                step="4"
                title="前回質問した内容を学習して、再度質問をし直せる"
                description="前回した質問の回答が求めている回答と違う場合、前提条件を追記して質問することで、より精度の高い回答が来る。"
              />
              <GuideSection
                type="POINT"
                step="5"
                title="月額制で利用できる"
                description="定額で月に何回質問しても5,500円で利用できる。"
              />
              <GuideSection
                type="POINT"
                step="6"
                title="専門家が迷う事例についてセカンドオピニオンとして使える"
                description="一人で判断すると不安になることもあるので、安心材料として使用できる。"
              />
              <GuideSection
                type="POINT"
                step="7"
                title="上司に相談しづらい内容も一度AI税務相談に聞いてから聞くことで的外れな質問を上司にしなくなる"
                description="上司にこれが分からないの？と思われないように、AI税務相談の回答を確認することが的外れな質問をしなくて済むようになるので、上司の評価が下がらないようにする。"
              />
              <GuideSection
                type="POINT"
                step="8"
                title="AI税務相談では根拠条文まで調べられる"
                description="どれを根拠に判断すればいいか迷った際に、質問することでどの根拠条文を利用すればいいかの目処が立つ。"
              />
            </>
          )}
          {activeTab === 'freelancers' && (
            <>
              <GuideSection
                type="POINT"
                step="1"
                title="即時に回答が来る"
                description="普通は専門家に聞くと、回答までに2-3日、1週間かかることは当たり前なので、24時間365日タイムリーに回答が来るのが利用者にとって有難い。"
              />
              <GuideSection
                type="POINT"
                step="2"
                title="各カテゴリに応じた、10種類の質問テンプレートを使用することで、質問内容を的確にまとめられる"
                description="利用者の前提を整理することができ、認識の齟齬をなくせる。"
              />
              <GuideSection
                type="POINT"
                step="3"
                title="誰でも精度の高い回答が来る、専門用語を使って噛み砕ける"
                description="質問者のレベルに応じた回答が来るので、会計・税務の実務に活かせる。"
              />
              <GuideSection
                type="POINT"
                step="4"
                title="前回質問した内容を学習して、再度質問をし直せる"
                description="前回した質問の回答が求めている回答と違う場合、前提条件を追記して質問することで、より精度の高い回答が来る。"
              />
              <GuideSection
                type="POINT"
                step="5"
                title="月額制で利用できる"
                description="定額で月に何回質問しても5,500円で利用できる。"
              />
              <GuideSection
                type="POINT"
                step="6"
                title="税理士が監修したAI税務相談なので回答の精度が高い"
                description="100%的確な回答が来るとは言い切れませんが、回答の精度が高いのが特長です。"
              />
              {/* 他のポイントも同様に追加 */}
            </>
          )}
          {/* 他の特徴についても同様にセクションを追加 */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;

// Emotionでのスタイル定義
const styles = {
  dashboardContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 100vh;
  `,
  logoStyle: css`
    width: 140px;
    margin-bottom: 30px;
  `,
  dashboardTitle: css`
    margin-bottom: 8px;
  `,
  dashboardSubtitle: css`
    margin-bottom: 24px;
  `,
  navigation: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3つのカラムを持つグリッドに変更
    gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
  `,
  navItem: css`
    background-color: white; // カードの背景色を設定
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px; // 角の丸みを増やす
    text-decoration: none;
    color: #003366;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // カードに影を追加
    transition: transform 0.3s, box-shadow 0.3s; // アニメーション効果を追加

    &:hover {
      transform: translateY(-5px); // ホバー時にカードを少し上に動かす
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // 影を大きくする
    }
  `,
  navIcon: css`
    font-size: 40px;
    margin-bottom: 5px;
  `,
  navText: css`
    font-size: 16px;
  `,
  serviceIntroductionContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
  `,
  serviceIntroductionTitle: css`
    padding-top: 30px;
    padding-bottom: 30px;
  `,
  serviceIntroductionSubtitle: css`
    padding-bottom: 30px;
    max-width: 570px;
  `,
  tabs: css`
    display: flex;
    gap: 10px;
  `,
  tab: (isActive) => css`
    padding: 10px 20px;
    border: none;
    background-color: ${isActive ? '#003870' : 'gray'};
    color: white;
    cursor: pointer;
  `,
};
