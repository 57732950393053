import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import AttachFileButton from '../shared/button/AttachFileButton';

const FileDropArea = ({ onFileSelected }) => {
  const onDragEnter = useCallback((event) => {
    console.log('onDragEnter');
    event.preventDefault();
  }, []);

  const onDragLeave = useCallback((event) => {
    console.log('onDragLeave');
    event.preventDefault();
  }, []);

  const handleDragOver = (event) => {
    console.log('handleDragOver');
    event.preventDefault(); // デフォルトのイベントをキャンセル
    event.stopPropagation(); // 親要素へのイベント伝播をキャンセル
  };

  const handleDrop = (event) => {
    console.log('handleDrop');
    event.preventDefault(); // デフォルトのイベントをキャンセル
    event.stopPropagation(); // 親要素へのイベント伝播をキャンセル
    if (event.dataTransfer.files.length > 0) {
      onFileSelected(event.dataTransfer.files[0]);
    }
  };

  return (
    <div
      css={styles.dragDropArea}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      ファイルをドラッグ＆ドロップしてください または{' '}
      <AttachFileButton onFileChange={(e) => onFileSelected(e.target.files[0])} mode="text" />
    </div>
  );
};

export default FileDropArea;

const styles = {
  dragDropArea: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    border: 2px dashed #007bff;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
  `,
};
