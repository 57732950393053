/*
  src/pages/LoginPage.js

  コメント未設定です。
*/

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Button from '../components/shared/button/Button';
import Input from '../components/shared/Input';
import Form from '../components/shared/Form';
import Logo from '../components/internal/Logo';
import Typography from '../components/shared/Typography';
import NavigationBar from '../components/internal/NavigationBar';
import { userLogin } from '../actions/userSession';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const userError = useSelector((state) => state.userSession.userError);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(userLogin(username, password))
      .then(() => navigate('/'))
      .catch(() => {}); // エラーハンドリングはすでにReduxアクション内で行われています。
  };

  return (
    <div css={styles.container}>
      <NavigationBar />
      <Form css={styles.form} onSubmit={handleSubmit}>
        <Logo alt="Logo" />
        <Typography variant="title">AI税務相談</Typography>
        <Typography variant="text">ログイン</Typography>
        <Input
          css={styles.input}
          type="text"
          placeholder="ユーザー名"
          size="large"
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          css={styles.input}
          type="password"
          placeholder="パスワード"
          size="large"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="primary" size="large">
          ログイン
        </Button>
        <Link to="/user-register" css={styles.registerButton}>
          <Button variant="primary" size="large">
            新規ユーザー登録はこちら
          </Button>
        </Link>
        {userError && (
          <Typography variant="text" color="red">
            {userError}
          </Typography>
        )}
      </Form>
    </div>
  );
}

export default LoginPage;

// Emotionでのスタイル定義
const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `,
  logo: css`
    width: 140px; // ロゴのサイズを調整
    margin-bottom: 10px; // ロゴ下の余白
  `,
  serviceName: css`
    font-size: 24px;
    font-weight: bold; // フォントの太さを増やす
    color: #002244; // コントラストを高めるために色を少し濃くする
    margin-bottom: 5px;
  `,
  adminLoginText: css`
    font-size: 18px;
    color: #002244; // コントラストを高めるために色を少し濃くする
    margin-bottom: 20px;
  `,
  input: css`
    margin-bottom: 10px;
  `,
  registerButton: css`
    margin-top: 10px;
    text-decoration-line: none;
  `,
};
