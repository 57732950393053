// src/config/categoriesConfig.js

import {
  faQuestionCircle,
  faBuilding,
  faUserTie,
  faChartLine,
  faHandHoldingUsd,
  faCalculator,
  faFileInvoiceDollar,
  faFileAlt,
  faGift,
  faSearchDollar,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

const categoriesConfig = {
  法人設立: {
    icon: faBuilding,
    description:
      '会社設立の手続きから税務上の注意点、資本金の設定まで、法人設立の基本をわかりやすく解説。ビジネススタートの重要な一歩をサポートします。',
  },
  個人開業: {
    icon: faUserTie,
    description: 'フリーランスや個人事業主へのサポート。開業の手順、税務処理、経費計上の基礎を解説します。',
  },
  顧問税理士: {
    icon: faChartLine,
    description: '顧問税理士は、税務・経理の専門家。節税策提案、申告サポート、経営戦略の税務面をサポートします。',
  },
  資金調達: {
    icon: faHandHoldingUsd,
    description:
      '資金調達は、銀行融資、ベンチャーキャピタル、エンジェル投資など多様な方法で行われ、事業発展を支えます。',
  },
  '経理・決算': {
    icon: faCalculator,
    description: '経理・決算は、取引の記録、帳簿作成、財務諸表の整理を通じて、企業の財務状況を正確に反映します。',
  },
  法人確定申告: {
    icon: faFileInvoiceDollar,
    description: '法人確定申告は、法人の年間所得を報告し税額を計算する重要な税務プロセスです。',
  },
  個人確定申告: {
    icon: faFileAlt,
    description: '個人確定申告は、年間所得と税控除を申告し、正確な税額を計算する手続きです。',
  },
  '相続税・贈与税': {
    icon: faGift,
    description: '相続税・贈与税は、遺産の移転に伴い発生する税金を計算し納付するプロセスです。',
  },
  '税務調査・節税・その他': {
    icon: faSearchDollar,
    description: '税務調査、節税対策、その他税に関わる多様な課題に対する実践的なガイダンスを提供します。',
  },
  税務調査: {
    icon: faMagnifyingGlass,
    description: '税務調査に関する重要な知識と、調査時の対応方法を提供し、リスクの最小化を支援します。',
  },
  default: {
    icon: faQuestionCircle,
    description: 'このカテゴリはQAの重要な質問を集めたものです。',
  },
};

export default categoriesConfig;
