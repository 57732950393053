// src/components/Typography.js

/**
 * Typographyコンポーネント
 *
 * テキスト要素をスタイリングするためのコンポーネントです。
 * 'title' や 'text' などのバリアントを使用して、異なるスタイルのテキストを表示します。
 */

import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const Typography = ({ variant, children, style }) => {
  return <div css={[variantStyle[variant], style]}>{children}</div>;
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['title', 'subtitle', 'text']).isRequired,
  children: PropTypes.node.isRequired,
};

export default Typography;

const variantStyle = {
  title: css`
    font-size: 24px;
    font-family: 'Noto Sans JP', 'system-ui', sans-serif;
    font-weight: bold; // フォントの太さを増やす
    color: #002244; // コントラストを高めるために色を少し濃くする
  `,
  subtitle: css`
    font-size: 18px;
    font-family: 'Noto Sans JP', 'system-ui', sans-serif;
    color: #002244; // コントラストを高めるために色を少し濃くする
  `,
  text: css`
    font-size: 18px;
    font-family: 'Noto Sans JP', sans-serif;
    color: #002244; // コントラストを高めるために色を少し濃くする
  `,
};
