import React, { useState } from 'react';
import { css } from '@emotion/react';
import SendMessageButton from './button/SendMessageButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import AttachFileButton from './button/AttachFileButton';

const UserInputBox = React.forwardRef(
  ({ templateInputMessage, setTemplateInputMessage, onSendMessage, onFileSelected }, ref) => {
    // userInputMessage はユーザーが直接入力したテキストを管理するための状態
    const [userInputMessage, setUserInputMessage] = useState('');

    const handleInputChange = (event) => {
      setUserInputMessage(event.target.value);
    };

    const handleSend = () => {
      // templateInputMessage と userInputMessage が文字列型であることを確認
      const templateMessage = typeof templateInputMessage === 'string' ? templateInputMessage : '';
      const userMessage = typeof userInputMessage === 'string' ? userInputMessage : '';

      const messageToSend = templateMessage !== '' ? templateMessage : userMessage;

      if (messageToSend && messageToSend.trim()) {
        onSendMessage(messageToSend.trim());
        setUserInputMessage('');
        setTemplateInputMessage('');
      }
    };

    const handleDetachTemplate = () => {
      setTemplateInputMessage(''); // テンプレート添付状態を解除
    };

    return (
      <>
        <div css={styles.inputBoxContainer}>
          {templateInputMessage === '' ? (
            <AttachFileButton onFileChange={(e) => onFileSelected(e.target.files[0])} mode="icon" />
          ) : (
            <div css={styles.attachmentIndicator}>
              <FontAwesomeIcon icon={faFileAlt} /> {/* ファイルアイコン */}
              <button css={styles.attachmentCancelButton} onClick={handleDetachTemplate}>
                <FontAwesomeIcon icon={faTimes} /> {/* 「×」マーク */}
              </button>
            </div>
          )}
          <textarea
            ref={ref}
            value={templateInputMessage ? '【質問テンプレートをお送りします。】' : userInputMessage} // templateInputMessageがある場合それを表示
            onChange={handleInputChange}
            css={styles.inputTextArea}
            placeholder="メッセージを入力..."
            readOnly={templateInputMessage !== ''} // templateInputMessageが空文字列でない場合、入力不可
          />
          <SendMessageButton onClick={handleSend} />
        </div>
      </>
    );
  }
);

const styles = {
  inputBoxContainer: css`
    display: flex;
    align-items: center;
    padding: 10px;
    background: #cae7ff;
    border-radius: 10px;
  `,
  attachmentIndicator: css`
    font-size: 25px;
  `,
  attachmentCancelButton: css`
    border: none;
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background-color: #636363;
    color: #fff;
    cursor: pointer;
  `,
  inputTextArea: css`
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 20px;
    resize: none;
    overflow-y: auto;
    min-height: 30px;
    max-height: 300px;
  `,
};

export default UserInputBox;
