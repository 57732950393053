// src/pages/QADetailPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';
import Typography from '../components/shared/Typography';
import Message from '../components/shared/Message';
import NavigationBar from '../components/internal/NavigationBar';
import Footer from '../components/internal/Footer';
import Button from '../components/shared/button/Button';
import QAList from '../components/qa/QAList';
import FloatingActionButton from '../components/shared/button/FloatingActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchModal from '../components/shared/modal/SearchModal';

function QADetailPage() {
  const navigate = useNavigate();
  const { questionId } = useParams();
  const [qa, setQA] = useState(null);
  const [relatedCategoryId, setRelatedCategoryId] = useState(null);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  useEffect(() => {
    const fetchQA = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/qa/question_answer/questions/${questionId}`);
        setQA(response.data);
        setRelatedCategoryId(response.data.related_category_id);
      } catch (error) {
        console.error('QAの取得に失敗しました', error);
      }
    };

    fetchQA();
  }, [questionId]);

  // 自分で相談するページへの遷移を処理
  const navigateToConsultMyself = () => {
    navigate(`/ai-chat`);
  };

  if (!qa) {
    return (
      <div css={styles.loader}>
        <BeatLoader color="#004c98" />
      </div>
    );
  }

  return (
    <div>
      <NavigationBar />
      {/* ブレッドクラムナビゲーション */}
      <Typography variant="title" style={styles.pageTitle}>
        よくあるQA
      </Typography>
      <Typography variant="subtitle" style={styles.pageSubtitle}>
        {qa.question_summary}
      </Typography>
      <div css={styles.questionSection}>
        <Message type="question" icon={faUser} text={qa.question} />
      </div>
      <div css={styles.answerSection}>
        <Message type="answer" icon={faUser} text={qa.answer} />
      </div>
      <div css={styles.consultMyselfSection}>
        <Button variant="primary" size="large" textAlign="center" onClick={navigateToConsultMyself}>
          自分で相談する
        </Button>{' '}
      </div>
      {/* 関連質問セクションを追加 */}
      <div css={styles.relatedQuestionsSection}>
        <QAList categoryId={relatedCategoryId} listTitle="関連するQA" excludeQuestionId={parseInt(questionId)} />
      </div>
      {/* 検索モーダル */}
      <FloatingActionButton tooltip="検索はこちら" onClick={() => setIsOpenSearchModal(true)}>
        <FontAwesomeIcon icon={faSearch} />
      </FloatingActionButton>
      <SearchModal
        isOpen={isOpenSearchModal}
        onClose={() => setIsOpenSearchModal(false)}
        onSearch={(keyword) => {
          setIsOpenSearchModal(false);
          // 検索結果ページへの遷移ロジック
          navigate(`/qa/search?query=${encodeURIComponent(keyword)}`);
        }}
        placeholder="キーワードを入力して質問を検索します"
      />
      <Footer />
    </div>
  );
}

// スタイル定義
const styles = {
  loader: css`
    display: flex;
    justify-content: center;
    padding: 50px; // ローディング中のスペースを調整
  `,
  pageTitle: css`
    padding-top: 40px;
    padding-bottom: 20px;
    margin: 0 auto; /* Center the grid */
    text-align: center;
    @media (max-width: 768px) {
      padding-top: 70px;
    }
  `,
  pageSubtitle: css`
    padding-bottom: 20px;
    margin: 0 auto; /* Center the grid */
    text-align: center;
  `,
  questionSection: css`
    padding: 20px 50px;

    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  answerSection: css`
    padding: 20px 50px;

    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  consultMyselfSection: css`
    display: flex; // Flexboxを有効化
    justify-content: center; // 水平方向の中央揃え
    margin: 20px 0; // 上下に余白を設定
  `,
  relatedQuestionsSection: css`
    padding: 20px 50px;
    margin-top: 30px;
    margin-bottom: 200px;

    // モバイルビューでのスタイル
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  relatedQuestionsTitle: css`
    margin-bottom: 10px;
    color: #16325c;
  `,
};

export default QADetailPage;
