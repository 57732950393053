// src/hooks/usePagination.js

import { useState } from 'react';

const usePagination = (totalItems, itemsPerPage) => {
  const totalPages = Math.ceil(totalItems.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(totalItems.length / itemsPerPage);

  function currentItems() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return totalItems.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  function getPageNumbers() {
    const pageNumbers = [];
    const pageWindow = 5;
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages <= pageWindow) {
      // 総ページ数がウィンドウサイズ以下の場合、全ページを表示
      startPage = 1;
      endPage = totalPages;
    } else {
      // 現在のページがウィンドウの中央に来るように調整
      const maxPagesBeforeCurrentPage = Math.floor(pageWindow / 2);
      const maxPagesAfterCurrentPage = Math.ceil(pageWindow / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // 現在のページが最初の数ページの内にある場合
        startPage = 1;
        endPage = pageWindow;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // 現在のページが最後の数ページの内にある場合
        startPage = totalPages - pageWindow + 1;
        endPage = totalPages;
      } else {
        // 現在のページが中央に来るようにする
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    let startNumberPage = startPage;
    let endNumberPage = endPage;

    // 省略記号の追加
    if (startPage > 1) {
      pageNumbers.push('...');
      startNumberPage = startPage + 1;
    }
    if (endPage < totalPages) {
      endNumberPage = endPage - 1;
    }
    for (let page = startNumberPage; page <= endNumberPage; page++) {
      pageNumbers.push(page);
    }
    if (endPage < totalPages) {
      pageNumbers.push('...');
    }

    return pageNumbers;
  }

  return { next, prev, jump, currentItems, currentPage, maxPage, getPageNumbers };
};

export default usePagination;
