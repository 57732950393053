/**
 * src/reducers/userSession.js
 *
 * このファイルは、ユーザーセッションに関連するReduxのリデューサーを定義します。
 * リデューサーは、アクションのタイプに基づいてアプリケーションのステートを更新します。
 *
 * 以下のステート更新が含まれます：
 *   - ユーザーログイン成功・失敗
 *   - ユーザーログアウト
 *   - アクセストークンのリフレッシュ成功
 *   - ユーザープロファイル情報の取得成功・失敗
 *   - トークンの有効性確認成功・失敗
 *
 * ステートは初期状態としてログイン情報、アクセストークン、リフレッシュトークン、
 * ユーザー名、ID、メールアドレス、メールアドレスの検証状況、権限、サービス、
 * エラーメッセージを保持します。
 */

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from '../actions/types';

const initialState = {
  userAccessToken: localStorage.getItem('gptdexTaxUserAccessToken'),
  userRefreshToken: localStorage.getItem('gptdexTaxUserRefreshToken'),
  isUserAuthenticated: Boolean(localStorage.getItem('gptdexTaxUserAccessToken')),
  userName: null,
  userId: null,
  emailAddress: null,
  emailAddressVerified: false,
  authority: null,
  service: null,
  userError: null,
};

function userSessionReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isUserAuthenticated: true,
        userAccessToken: action.payload.access_token,
        userRefreshToken: action.payload.refresh_token,
        userName: action.payload.username,
        userId: action.payload.userid,
        emailAddress: action.payload.email_address,
        emailAddressVerified: action.payload.email_address_verified,
        authority: action.payload.authority,
        service: action.payload.service,
        userError: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isUserAuthenticated: false,
        userError: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isUserAuthenticated: false,
        userAccessToken: null,
        userRefreshToken: null,
        userName: null,
        userId: null,
        emailAddress: null,
        emailAddressVerified: false,
        authority: null,
        service: null,
        userError: null,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        userAccessToken: action.payload,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        userName: action.payload.username,
        userId: action.payload.userid,
        emailAddress: action.payload.email_address,
        emailAddressVerified: action.payload.email_address_verified,
        authority: action.payload.authority,
        service: action.payload.service,
        userError: null,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        userError: action.payload,
      };
    case VALIDATE_TOKEN_SUCCESS:
      return state; // トークンが有効な場合はステートを変更せず
    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        userError: action.payload,
      };
    default:
      return state;
  }
}

export default userSessionReducer;
