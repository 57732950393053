import React from 'react';
import { css } from '@emotion/react';

const floatingActionButtonStyle = css`
  position: absolute;
  z-index: 9999;
  right: 0;
  bottom: 0;
  font-size: 30px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const containerStyle = css`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: white;
  text-align: center;
  color: #666;
  padding-right: 70px;
  padding-left: 30px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 60px;
  font-weight: 600;
  font-family: Inter, NotoSansKR, NotoSansJP, -apple-system, BlinkMacSystemFont, Helvetica Neue, Segoe UI, Roboto,
    'system-ui', sans-serif;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
`;

const tooltipStyle = css`
  user-select: none; // テキストの選択を無効にする
`;

const FloatingActionButton = ({ children, tooltip, onClick, style }) => {
  return (
    <div css={[containerStyle, style]} onClick={onClick}>
      <div css={floatingActionButtonStyle}>{children}</div>
      <div css={tooltipStyle}>{tooltip}</div> {/* tooltipテキストをdivで囲む */}
    </div>
  );
};

export default FloatingActionButton;
