import React from 'react';
import { css } from '@emotion/react';

const Pagination = ({ style, currentPage, onNextPage, onPrevPage, onJumpPage, pageNumbers }) => {
  return (
    <nav css={[styles.pagination, style]}>
      <a
        css={[styles.pageItem('prevNext'), styles.prev]}
        href="#!"
        onClick={(e) => {
          e.preventDefault();
          onPrevPage();
        }}
      >
        <span className="visuallyhidden">Previous Page</span>
      </a>
      <ul css={styles.items}>
        {pageNumbers.map((page, index) => (
          <li key={index} css={styles.pageItem(currentPage === page ? 'active' : 'inactive')}>
            {page === '...' ? (
              <span>{page}</span>
            ) : (
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  onJumpPage(page);
                }}
              >
                {page}
              </a>
            )}
          </li>
        ))}
      </ul>
      <a
        css={[styles.pageItem('prevNext'), styles.next]}
        href="#!"
        onClick={(e) => {
          e.preventDefault();
          onNextPage();
        }}
      >
        <span className="visuallyhidden">Next Page</span>
      </a>
    </nav>
  );
};

export default Pagination;

const styles = {
  pagination: css`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  `,
  items: css`
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    margin: 0 2px;

    li {
      margin: 0 2px;
    }
  `,
  pageItem: (type) => css`
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0 2px;
    color: ${type === 'active' ? 'white' : 'black'};
    background-color: ${type === 'active' ? 'black' : '#eee'};
    cursor: ${type === 'ellipsis' ? 'default' : 'pointer'};
    ${type === 'ellipsis' && 'pointer-events: none;'}

    a {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: ${type === 'active' ? 'white' : 'black'};
    }

    span {
      user-select: none;
    }

    &:hover,
    &:focus {
      ${type === 'active' && 'background-color: black;'}
      ${(type === 'inactive' || type === 'prevNext') && 'background-color: #ddd;'}
      ${type === 'ellipsis' && 'background-color: #eee;'}
    }
  `,
  prev: css`
    &::before {
      display: inline-block;
      content: '<';
    }
  `,
  next: css`
    &::before {
      display: inline-block;
      content: '>';
    }
  `,
};
