// src/components/Input.js

/**
 * 汎用的な入力フィールドコンポーネント
 *
 * このコンポーネントは、テキスト、パスワード、メールなどの様々なタイプの入力を受け付けます。
 * 'small', 'medium', 'large' のサイズオプションがあり、
 * レスポンシブデザインに対応しているため、デバイスのサイズに合わせて適切なスタイルが適用されます。
 */

import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

// Inputコンポーネントの定義
const Input = ({ style, placeholder, type, size, value, ...props }) => {
  return (
    <input css={[inputStyle, sizeStyle[size], style]} type={type} placeholder={placeholder} value={value} {...props} />
  );
};

// propTypesで入力フィールドの型をチェック
Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'search', 'url']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

// defaultPropsでデフォルトのプロパティ値を設定
Input.defaultProps = {
  type: 'text',
  size: 'medium',
};

export default Input;

// 共通の入力フィールドスタイル
const inputStyle = css`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    border-color: #004080; // フォーカス時にボーダーの色を変える
  }
`;

// サイズに応じたスタイル
const sizeStyle = {
  null: css``, // サイズ指定がない場合は追加スタイルなし
  small: css`
    padding: 10px;
    min-width: 230px;
  `,
  medium: css`
    padding: 10px;
    min-width: 280px;
  `,
  large: css`
    padding: 10px;
    min-width: 330px;
  `,
};
