// src/components/Message.js

import React from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Message = ({ icon, text, type }) => {
  const sender = getSender(type);
  const styles = getStyles(type);

  icon = icon || faUser;

  return (
    <div css={styles.messageWrapper}>
      <div css={styles.container}>
        <div css={styles.sender}>{sender}</div>
        <span css={styles.iconContainer}>
          <FontAwesomeIcon icon={icon} css={styles.icon} />
        </span>
        <p css={styles.text}>{text}</p>
      </div>
    </div>
  );
};

// スタイル定義
const getStyles = (type) => {
  let alignItems, flexDirection, bubbleColor, senderLeft, senderRight, borderColor, textColor, iconColor;

  switch (type) {
    case 'question':
      alignItems = 'flex-start';
      flexDirection = 'row';
      bubbleColor = '#e9fcef';
      borderColor = 'transparent';
      senderLeft = '10px';
      senderRight = 'auto';
      textColor = '#28a745';
      iconColor = '#28a745';
      break;
    case 'answer':
      alignItems = 'flex-end';
      flexDirection = 'row-reverse';
      bubbleColor = '#16325c1a';
      borderColor = 'transparent';
      senderLeft = 'auto';
      senderRight = '10px';
      textColor = '#16325c';
      iconColor = '#16325c';
      break;
    case 'user':
      alignItems = 'flex-start';
      flexDirection = 'row';
      bubbleColor = '#f0f0f0';
      borderColor = 'transparent';
      senderLeft = '10px';
      senderRight = 'auto';
      textColor = '#333';
      iconColor = '#6c757d';
      break;
    case 'ai':
      alignItems = 'flex-end';
      flexDirection = 'row-reverse';
      bubbleColor = '#d0e0fd';
      borderColor = 'transparent';
      senderLeft = 'auto';
      senderRight = '10px';
      textColor = '#0056b3';
      iconColor = '#007bff';
      break;
    default:
      alignItems = 'flex-start';
      flexDirection = 'row';
      bubbleColor = '#f8f8f8';
      borderColor = 'transparent';
      senderLeft = '10px';
      senderRight = 'auto';
      textColor = '#666';
      iconColor = '#666';
  }

  return {
    messageWrapper: css`
      display: flex;
      align-items: ${alignItems};
      flex-direction: column;
      width: 100%;
    `,
    container: css`
      // チャットバブル風のスタイリングを追加
      position: relative;
      width: 75%;
      border-radius: 18px;
      padding: 0 10px;
      margin: 10px 0;
      display: flex;
      flex-direction: ${flexDirection};
      align-items: center;
      background-color: ${bubbleColor};
      border: 1px solid ${borderColor};

      @media (max-width: 768px) {
        width: calc(100% - 32px);
        width: 85%;
      }
    `,
    sender: css`
      font-size: 0.75rem;
      color: #666666;
      left: ${senderLeft};
      right: ${senderRight};
      margin-bottom: -6px;
      align-self: flex-start; // ラベルの配置を左側に
      position: absolute;
      top: -20px;
    `,
    iconContainer: css`
      font-size: 30px;
      color: ${iconColor};
      border-radius: 50%; // アイコンを丸く囲む
      text-align: center;
      width: 45px;
      height: 45px;
      flex-shrink: 0; // コンテナが縮小しないようにする
      background-color: #fff; // アイコンの背景色を白に設定
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1); // 軽い影を追加

      @media (max-width: 768px) {
      }
    `,
    text: css`
      flex-grow: 1;
      text-align: left;
      margin-left: 10px;
      color: ${textColor};
      white-space: pre-wrap;

      @media (max-width: 768px) {
        margin-left: 5px;
      }
    `,
    default: css`
      // デフォルトスタイル（必要に応じて定義）
    `,
  };
};

const getSender = (type) => {
  let sender;
  switch (type) {
    case 'question':
      sender = '質問';
      break;
    case 'answer':
      sender = '回答';
      break;
    case 'user':
      sender = 'ユーザー';
      break;
    case 'ai':
      sender = 'AI';
      break;
    default:
  }
  return sender;
};

export default Message;
