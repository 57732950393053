// src/components/shared/button/AttachFileButton.js

import React from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const AttachFileButton = ({ onFileChange, mode = 'icon' }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <button css={mode === 'icon' ? styles.attachIconButton : styles.attachTextButton} onClick={handleClick}>
        {mode === 'icon' ? <FontAwesomeIcon icon={faPaperclip} /> : 'ファイル選択'}
      </button>
      <input type="file" ref={hiddenFileInput} onChange={onFileChange} style={{ display: 'none' }} />
    </>
  );
};

const styles = {
  attachIconButton: css`
    padding: 10px;
    background: transparent;
    border: none;
    border-radius: 50%;
    color: #007bff;
    cursor: pointer;
    font-size: 20px;
    &:hover {
      color: #0056b3;
    }
  `,
  attachTextButton: css`
    font-size: 16px;
    color: #16325c;
    background-color: transparent;
    border: 2px solid #666666;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    min-width: 100px;
    transition: background-color 0.3s, transform 0.1s;

    &:hover {
      background: #cae7ff;
      border: 2px solid #007bff;
    }
    &:focus {
      background: #cae7ff;
      border: 2px solid #007bff;
    }
  `,
};

export default AttachFileButton;
